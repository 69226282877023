import React, { useEffect } from "react";
import { MessageList } from "../../Components/MessageList";
import { useMessage } from "../../Hooks/Message";

export const BotMessage: React.FC = () => {
  const usedMessage = useMessage("bot");
  const { updateMessageList } = usedMessage;

  useEffect(() => {
    updateMessageList();
  }, []);
  return (
    <>
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h3>Robo</h3>
        </div>
        <MessageList flags={["{{Contato}}"]} usedMessage={usedMessage} />
      </div>
    </>
  );
};
