import "moment/locale/pt-br";
import React, { useEffect, useState } from "react";
import "react-datetime/css/react-datetime.css";
import { useConfirm } from "../../Hooks/Confirm";
import { useModal } from "../../Hooks/Modal";
import { useCupom } from "./hook";

import { useInstancieContext } from "../../Context/instancie";
import { IListItemProp, IModalCupom, ICupomListData } from "./types";

export const Cupons: React.FC = () => {
  const {
    createCupom,
    setFormData,
    formValues,
    cupomList,
    updateCupomList,
    loadingList,
  } = useCupom();

  const {
    Modal,
    show: openModalInstancie,
    hide: closeModalInstancie,
  } = useModal();

  useEffect(() => {
    updateCupomList();
  }, []);

  return (
    <>
      <Modal
        title="Criar Cupom"
        footer={
          <div className="d-flex gap-3">
            <button
              type="button"
              onClick={() => {
                createCupom(() => {
                  updateCupomList();
                  closeModalInstancie();
                });
              }}
              className="btn btn-success"
            >
              Salvar
            </button>
            <button
              onClick={closeModalInstancie}
              type="button"
              className="btn btn-secondary"
            >
              Cancelar
            </button>
          </div>
        }
      >
        <ModalCupom setFormData={setFormData} formValues={formValues} />
      </Modal>
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <h3>Cupons</h3>
            <button
              type="button"
              onClick={openModalInstancie}
              className="btn btn-primary"
            >
              Novo cupom
            </button>
          </div>
        </div>
        <div className="card-body p-2">
          {!loadingList ? (
            cupomList.map((enqueteData, key) => (
              <ListItem
                updateCupomList={updateCupomList}
                item={enqueteData}
                key={`ItemCupomList_${key}`}
              />
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

const ListItem: React.FC<IListItemProp> = ({ item, updateCupomList }) => {
  const {
    Confirm: ConfirmDelete,
    show: openDeleteConfirm,
    hide: closeDeleteConfirm,
  } = useConfirm();
  const [updating, setUpdating] = useState(false);
  const [itemList, setItemList] = useState<ICupomListData>(item);
  const {
    Modal,
    show: openModalInstancie,
    hide: closeModalInstancie,
  } = useModal();

  const {
    deleteCupom,
    updateCupom,
    setFormData,
    formValues,
    fillFormDataWithItem,
  } = useCupom();
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    fillFormDataWithItem(itemList);
  }, [itemList]);

  const buttonsEnabled =
    itemList.fixo == 1 ? true : itemList.cadastradoNatura == 1 ? false : true;

  return (
    <>
      <ConfirmDelete
        btnLabel="Remover"
        btnColor="danger"
        callback={() => {
          deleteCupom(itemList.id, () => {
            updateCupomList();
            closeDeleteConfirm();
          });
        }}
        title="Deseja realmente remover o cupom?"
      >
        Deseja realmente remover o cupom? A remoção é permanente.
      </ConfirmDelete>

      <Modal
        title="Editar Cupom"
        footer={
          <div className="d-flex gap-3">
            <button
              type="button"
              onClick={() => {
                updateCupom(itemList.id, () => {
                  updateCupomList();
                  closeModalInstancie();
                });
              }}
              className="btn btn-success"
            >
              Salvar
            </button>
            <button
              onClick={closeModalInstancie}
              type="button"
              className="btn btn-secondary"
            >
              Cancelar
            </button>
          </div>
        }
      >
        <ModalCupom
          id={itemList.id}
          setFormData={setFormData}
          formValues={formValues}
        />
      </Modal>

      <div
        className={`d-flex flex-column flex-md-row border-1 rounded bg-secondary bg-opacity-10 my-3 p-3 justify-content-between ${
          updating ? "opacity-25" : ""
        }`}
      >
        <div className="d-flex flex-column flex-md-row w-100 justify-content-between">
          <div className="d-flex col-2 flex-column mx-3">
            <b>Nome:</b>
            <span>{itemList.cupom}</span>
          </div>
          <div className="d-flex  col-2 flex-column mx-3">
            <b>Tipo:</b>
            <span>{itemList.fixo == 1 ? "Natura" : "Consultor"}</span>
          </div>
          <div className="d-flex  col-2 flex-column mx-3">
            <b>Desconto:</b>
            <span>{itemList.percDesconto}%</span>
          </div>
          <div className="d-flex  col-2 flex-column mx-3">
            <b>Cupom disponíveis:</b>
            <span>
              {itemList.fixo == 1 ? "Ilimitado" : itemList.qtdDisponivel}
            </span>
          </div>
          <div className="d-flex col-2 flex-column mx-3">
            <b>Status:</b>
            <span>
              {itemList.fixo == 1
                ? "Ativo"
                : itemList.cadastradoNatura == 1 && itemList.isActive == 1
                ? "Em andamento"
                : "Aguardando criação"}
            </span>
          </div>
        </div>
        <div className="d-flex">
          <div
            className={`position-relative ${showMenu ? "d-block" : "d-block"}`}
          >
            <div className="d-flex flex-row mx-3 gap-2">
              <button
                disabled={!buttonsEnabled}
                onClick={openModalInstancie}
                type="button"
                className="btn btn-warning"
              >
                Editar
              </button>
              <button
                disabled={!buttonsEnabled}
                onClick={openDeleteConfirm}
                type="button"
                className="btn btn-danger"
              >
                Remover
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ModalCupom: React.FC<IModalCupom> = ({
  setFormData,
  formValues,
  id = 0,
}) => {
  const { instanciesList } = useInstancieContext();

  return (
    <div className="d-flex flex-column">
      <div className=" border-1 p-2">
        <div className="d-flex flex-row align-items-end">
          <div className="mb-3 col-8">
            <label className="form-label">Cupom:</label>
            <input
              type="text"
              className="form-control"
              defaultValue={formValues.cupom}
              onChange={(event) => {
                setFormData("cupom", event.target.value);
              }}
            />
          </div>

          <div className="mb-3 col-4 ps-3">
            <label className="form-label">Fixo:</label>
            <input
              type="checkbox"
              className="form-check-input mx-2"
              defaultChecked={formValues.fixo ? true : false}
              onChange={(event) => {
                setFormData("fixo", event.target.checked ? 1 : 0);
              }}
            />
          </div>
        </div>
        <div className="d-flex flex-row align-items-end">
          <div className="mb-3 col-8">
            <label className="form-label">Percentual desconto:</label>
            <input
              type="text"
              className="form-control"
              defaultValue={formValues.percDesconto}
              onChange={(event) => {
                setFormData("percDesconto", event.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
