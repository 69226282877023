import React, { useState } from "react";
import { api } from "../../Services/api";
import { IBagList, IGetStatsBags } from "./types";

export const useBags = () => {
  const [bagList, setBagList] = useState<Array<IBagList>>();
  const [statsList, setStatsList] = useState<IGetStatsBags>();

  const updateBaglist = () => {
    api.get<Array<IBagList>>("/bags/pending").then(({ data }) => {
      setBagList(data);
    });
  };

  const updateStatsList = () => {
    api.get<IGetStatsBags>("/bags/stats").then(({ data }) => {
      setStatsList(data);
    });
  };
  return { bagList, updateBaglist, updateStatsList, statsList };
};
