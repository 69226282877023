import React, { createContext, PropsWithChildren, useContext } from "react";

import { io, Socket } from "socket.io-client";
import { CONFIG } from "../../Config";
type defaultSocket = Socket<any>;

interface ISocketContext {
  socket?: defaultSocket;
}

const SocketContext = createContext<ISocketContext>({} as ISocketContext);

export const SocketProvider: React.FC<PropsWithChildren> = ({ children }) => {
  //const [socket, setSocket] = useState<defaultSocket>(io(CONFIG.urlBackend));
  const socket = io(CONFIG.urlBackend);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => {
  const context = useContext(SocketContext);
  return context;
};
