import axios, { HeadersDefaults } from "axios";
import { CONFIG } from "../../../Config";

interface CommonHeaderProperties extends HeadersDefaults {
  Authorization: string;
}

export const api = axios.create({
  baseURL: CONFIG.urlBackend,
});

api.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
api.defaults.headers.get["Content-Type"] = "application/json;charset=utf-8";

export const setAuthorizationAccessToken = (accessToken: string) => {
  api.defaults.headers = {
    Authorization: `Bearer ${accessToken}`,
  } as CommonHeaderProperties;
};

export const clearAuthorizationAccessToken = () => {
  api.defaults.headers = {
    Authorization: "",
  } as CommonHeaderProperties;
};
