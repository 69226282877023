import { HeadersDefaults } from "axios";
import { set } from "lodash";
import { useEffect, useState } from "react";
import { api } from "../../Services/api";
import { IGruposForm, IGruposListData } from "./types";

interface CommonHeaderProperties extends HeadersDefaults {
  Authorization: string;
}
export interface IUseGrupos {
  gruposList: Array<IGruposListData>;
  updateGrupoList: () => void;
  updateGrupo: (
    grupoId: number,
    callback: () => void,
    data?: IGruposListData
  ) => void;
  getGrupo: (
    grupoId: number,
    callback: (instancieData: IGruposListData) => void
  ) => void;
  setFormData: (field: keyof IGruposForm, value: string | number) => void;
  formValues: IGruposForm;
  fillFormDataWithItem: (item: IGruposListData) => void;
}
export const useGrupos = (): IUseGrupos => {
  const [gruposList, setGruposList] = useState<Array<IGruposListData>>([]);
  const [formValues, setFormValues] = useState<IGruposForm>({
    nome: "",
    total_contatos: 0,
    msg_boas_vindas: "",
    replicar_mensagem: 1,
  });
  useEffect(() => {
    console.log("grupos list alterado:", gruposList);
  }, [gruposList]);
  const fillFormDataWithItem = (item: IGruposListData) => {
    setFormValues({
      ...item,
      replicar_mensagem: 1,
    });
  };

  const setFormData = (field: keyof IGruposForm, value: string | number) => {
    setFormValues((oldValue) => {
      set(oldValue, field, value);
      return oldValue;
    });
  };

  const getGrupo = (
    grupoId: number,
    callback: (instancieData: IGruposListData) => void
  ) => {
    api
      .get(`/grupos/${grupoId}`)
      .then(({ data }) => {
        callback(data);
      })
      .catch((error) => console.log("error", error));
  };

  const updateGrupo = (
    grupoId: number,
    callback: () => void,
    data?: IGruposListData
  ) => {
    api.put(`/grupos/${grupoId}`, data ? data : formValues).then((response) => {
      console.log(response);
      callback();
    });
  };
  const updateGrupoList = async () => {
    console.log("Chamando a atualizaçào da lista");
    api
      .get<Array<IGruposListData>>("/grupos")
      .then(({ data }) => {
        console.log("Lista atualizada");
        setGruposList(data);
      })
      .catch((error) => console.log("error:", error));
  };

  return {
    gruposList,
    updateGrupoList,
    updateGrupo,
    getGrupo,
    setFormData,
    formValues,
    fillFormDataWithItem,
  };
};
