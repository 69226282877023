import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { CONFIG } from "../../../Config";
import { useConfirm } from "../../Hooks/Confirm";
import { useImport } from "../../Hooks/Import";
import { IModalMessage, useMessage } from "../../Hooks/Message";
import { useModal } from "../../Hooks/Modal";
import { IMessage } from "../../types";
import { useInstancies } from "../Instancies/hook";
import { useBags } from "./hook";
import { ModalSender } from "../../Components/ModalSender";
import { useAuthContext } from "../../Context/auth";

export const Login: React.FC = () => {
  const { auth, error } = useAuthContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <>
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h3>Login</h3>
        </div>
        <div className="card-body d-flex align-items-center justify-content-center">
          <div className="col-md-3 col-9 d-flex flex-column gap-3">
            <span>Usuário:</span>
            <input
              onChange={(event) => {
                setUsername(event.currentTarget.value);
              }}
              defaultValue={username}
              className="border rounded p-2"
              type="text"
              placeholder="Usuário"
            />
            <span>Senha:</span>
            <input
              onChange={(event) => {
                setPassword(event.currentTarget.value);
              }}
              defaultValue={password}
              className="border rounded p-2"
              type="password"
              placeholder="Senha"
            />
            {error && (
              <small className="text-danger">
                Dados de login inválidos. Tente novamente.
              </small>
            )}
            <button
              onClick={() => {
                auth(username, password);
              }}
              type="button"
              className="btn btn-primary"
            >
              Entrar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
