import React from "react";
import { Link } from "react-router-dom";
import { LayoutMenu } from "./Components/Menu";
import { useAuthContext } from "../../App/Context/auth";

export const LayoutDefault: React.FC<any> = ({ children }) => {
  const { isAuth, logout } = useAuthContext();
  return (
    <div className="container my-4">
      <div className="d-flex justify-content-between">
        <h1>Whatsapp Sender</h1>
        {isAuth && (
          <button
            onClick={logout}
            type="button"
            className="btn btn-danger btn-sm"
          >
            Sair
          </button>
        )}
      </div>
      <LayoutMenu />
      {children}
    </div>
  );
};
