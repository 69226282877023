import moment from "moment";
import "moment/locale/pt-br";
import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useConfirm } from "../../Hooks/Confirm";
import { useModal } from "../../Hooks/Modal";
import { useSorteios } from "./hook";

import { VirtualMessageList } from "../../Components/VirtualMessageList";
import { useVirtualMessage } from "../../Hooks/VirtualMessage";
import {
  IEnqueteOrRespostaData,
  IListItemProp,
  IModalRespostaEnquete,
  IModalSorteio,
  IOptionsToIngress,
  ISorteioForm,
  ISorteioListData,
} from "./types";
import { useInstancieContext } from "../../Context/instancie";

export const Sorteios: React.FC = () => {
  const [enqueteRespostaData, setEnqueteRespostaData] =
    useState<IEnqueteOrRespostaData>({ id: 0, name: "" });

  const changeEnqueteRespostaData = (data: IEnqueteOrRespostaData) => {
    setEnqueteRespostaData(data);
  };

  const clearEnqueteRespostaData = () => {
    setEnqueteRespostaData({ id: 0, name: "" });
  };

  return <EnquetesList changeEnqueteResposta={changeEnqueteRespostaData} />;
};

interface IEnquetesListProps {
  changeEnqueteResposta: (data: IEnqueteOrRespostaData) => void;
}
const EnquetesList: React.FC<IEnquetesListProps> = ({
  changeEnqueteResposta,
}) => {
  const {
    createSorteio,
    setFormData,
    formValues,
    sorteioList,
    updateSorteiosList,
    loadingList,
  } = useSorteios();

  const {
    Modal,
    show: openModalInstancie,
    hide: closeModalInstancie,
  } = useModal();

  useEffect(() => {
    updateSorteiosList();
  }, []);

  return (
    <>
      <Modal
        title="Criar Sorteio"
        footer={
          <div className="d-flex gap-3">
            <button
              type="button"
              onClick={() => {
                createSorteio(() => {
                  updateSorteiosList();
                  closeModalInstancie();
                });
              }}
              className="btn btn-success"
            >
              Salvar
            </button>
            <button
              onClick={closeModalInstancie}
              type="button"
              className="btn btn-secondary"
            >
              Cancelar
            </button>
          </div>
        }
      >
        <ModalSorteio setFormData={setFormData} formValues={formValues} />
      </Modal>
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <h3>Sorteios</h3>
            <button
              type="button"
              onClick={openModalInstancie}
              className="btn btn-primary"
            >
              Novo sorteio
            </button>
          </div>
        </div>
        <div className="card-body p-2">
          {!loadingList ? (
            sorteioList.map((enqueteData, key) => (
              <ListItem
                changeEnqueteResposta={changeEnqueteResposta}
                updateSorteioList={updateSorteiosList}
                item={enqueteData}
                key={`ItemInstancieList_${key}`}
              />
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

const ListItem: React.FC<IListItemProp> = ({
  item,
  updateSorteioList,
  changeEnqueteResposta,
}) => {
  const {
    Confirm: ConfirmDelete,
    show: openDeleteConfirm,
    hide: closeDeleteConfirm,
  } = useConfirm();
  const [updating, setUpdating] = useState(false);
  const [itemList, setItemList] = useState<ISorteioListData>(item);
  const {
    Modal,
    show: openModalInstancie,
    hide: closeModalInstancie,
  } = useModal();

  const {
    deleteSorteio,
    updateSorteio,
    setFormData,
    formValues,
    fillFormDataWithItem,
    duplicateSorteio,
  } = useSorteios();
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    fillFormDataWithItem(itemList);
  }, [itemList]);

  const switchEnquete = () => {
    if (!updating) {
      setUpdating(true);
      const item = { ...itemList, ativo: itemList.ativo ? 0 : 1 };
      setItemList(item);
      fillFormDataWithItem(item);
      updateSorteio(
        itemList.id,
        () => {
          setUpdating(false);
        },
        item
      );
    }
  };
  return (
    <>
      <ConfirmDelete
        btnLabel="Remover"
        btnColor="danger"
        callback={() => {
          deleteSorteio(itemList.id, () => {
            updateSorteioList();
            closeDeleteConfirm();
          });
        }}
        title="Deseja realmente remover o sorteio?"
      >
        Deseja realmente remover o sorteio? A remoção é permanente.
      </ConfirmDelete>

      <Modal
        title="Editar Sorteio"
        footer={
          <div className="d-flex gap-3">
            <button
              type="button"
              onClick={() => {
                updateSorteio(itemList.id, () => {
                  updateSorteioList();
                  closeModalInstancie();
                });
              }}
              className="btn btn-success"
            >
              Salvar
            </button>
            <button
              onClick={closeModalInstancie}
              type="button"
              className="btn btn-secondary"
            >
              Cancelar
            </button>
          </div>
        }
      >
        <ModalSorteio
          id={itemList.id}
          setFormData={setFormData}
          formValues={formValues}
        />
      </Modal>

      <div
        className={`d-flex flex-column flex-md-row border-1 rounded bg-secondary bg-opacity-10 my-3 p-3 justify-content-between ${
          updating ? "opacity-25" : ""
        }`}
      >
        <div className="d-flex flex-column flex-md-row w-100 justify-content-between">
          <div className="d-flex flex-column mx-3">
            <b>Nome:</b>
            <span>{itemList.nome}</span>
          </div>

          <div className="d-flex flex-column mx-3">
            <b>Ativo:</b>
            <div className="form-check form-switch">
              <input
                className="form-check-input cursor-pointer"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                onClick={switchEnquete}
                checked={itemList.ativo ? true : false}
              />
            </div>
            {/* <span>{item.ativo ? "Ativo" : "Inativo"}</span> */}
          </div>
        </div>
        <div
          className="d-flex"
          //onMouseEnter={() => {
          //  setShowMenu(true);
          //}}
          //onMouseLeave={() => {
          //  setShowMenu(false);
          //}}
        >
          <div
            className={`position-relative ${showMenu ? "d-block" : "d-block"}`}
          >
            <div className="d-flex flex-row mx-3 gap-2">
              {/* {!item.started && (
              <button
                type="button"
                onClick={openStartInstancieModal}
                className="btn btn-primary"
              >
                Iniciar
              </button>
            )} */}

              <button
                onClick={openModalInstancie}
                type="button"
                className="btn btn-warning"
              >
                Editar
              </button>
              <button
                onClick={openDeleteConfirm}
                type="button"
                className="btn btn-danger"
              >
                Remover
              </button>
              <button
                onClick={() => {
                  window.open(
                    `https://www.yesfb.com/participantes/sorteio?id=${itemList.id}`
                  );
                }}
                type="button"
                className="btn btn-success"
              >
                Participantes
              </button>
              <button
                onClick={() => {
                  duplicateSorteio(itemList.id, updateSorteioList);
                }}
                type="button"
                className="btn btn-dark"
              >
                Duplicar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

type sections =
  | "reply"
  | "double"
  | "outdate"
  | "iniciada"
  | "winner"
  | "group";
type configSections = "config" | "message" | "instancies";

const ModalSorteio: React.FC<IModalSorteio> = ({
  setFormData,
  formValues,
  id = 0,
}) => {
  const { instanciesList } = useInstancieContext();
  moment.locale("pt-br");
  const usedVirtualMessageReply = useVirtualMessage<ISorteioForm>({
    keyFormValue: "resposta_sucesso",
    setFormOutedData: setFormData,
  });
  const usedVirtualMessageWinner = useVirtualMessage<ISorteioForm>({
    keyFormValue: "mensagem_ganhador",
    setFormOutedData: setFormData,
  });

  const usedVirtualMessageGroup = useVirtualMessage<ISorteioForm>({
    keyFormValue: "mensagem_grupo",
    setFormOutedData: setFormData,
  });
  const usedVirtualMessageDuplicated = useVirtualMessage<ISorteioForm>({
    keyFormValue: "resposta_duplicada",
    setFormOutedData: setFormData,
  });
  const usedVirtualMessageOutDate = useVirtualMessage<ISorteioForm>({
    keyFormValue: "resposta_encerrada",
    setFormOutedData: setFormData,
  });
  const usedVirtualMessageIniciada = useVirtualMessage<ISorteioForm>({
    keyFormValue: "resposta_iniciada",
    setFormOutedData: setFormData,
  });
  const [selectedSection, setSelectedSection] = useState<sections>("reply");
  const [selectedConfigSection, setSelectedConfigSection] =
    useState<configSections>("config");
  const [selectedInstancie, setSelectedInstancie] = useState<Array<Number>>([]);

  useEffect(() => {
    changeSection("reply");
  }, []);

  const changeSection = (section: sections) => {
    setSelectedSection(section);
  };

  //useEffect(() => {
  //  console.log("chamando");
  //  setFormData(
  //    "resposta_sucesso",
  //    JSON.stringify(usedVirtualMessageReply.messageList)
  //  );
  //}, [usedVirtualMessageReply.messageList]);
  //
  //useEffect(() => {
  //  setFormData(
  //    "resposta_duplicada",
  //    JSON.stringify(usedVirtualMessageDuplicated.messageList)
  //  );
  //}, [usedVirtualMessageDuplicated.messageList]);
  //
  //useEffect(() => {
  //  setFormData(
  //    "resposta_encerrada",
  //    JSON.stringify(usedVirtualMessageOutDate.messageList)
  //  );
  //}, [usedVirtualMessageOutDate.messageList]);

  const changeInstancieList = (instancieId: Number) => {
    if (selectedInstancie.find((instancie) => instancie === instancieId)) {
      setSelectedInstancie((oldValue) => {
        const data = oldValue.filter((instancie) => instancie !== instancieId);
        setFormData("instancias", JSON.stringify(data));
        return data;
      });
      return;
    }
    setSelectedInstancie((oldValue) => {
      const data = [...oldValue, instancieId];
      setFormData("instancias", JSON.stringify(data));
      return data;
    });
  };

  useEffect(() => {
    usedVirtualMessageReply.setMessageList(
      formValues.resposta_sucesso ? JSON.parse(formValues.resposta_sucesso) : []
    );
    usedVirtualMessageDuplicated.setMessageList(
      formValues.resposta_duplicada
        ? JSON.parse(formValues.resposta_duplicada)
        : []
    );
    usedVirtualMessageOutDate.setMessageList(
      formValues.resposta_encerrada
        ? JSON.parse(formValues.resposta_encerrada)
        : []
    );
    usedVirtualMessageIniciada.setMessageList(
      formValues.resposta_iniciada
        ? JSON.parse(formValues.resposta_iniciada)
        : []
    );
    usedVirtualMessageWinner.setMessageList(
      formValues.mensagem_ganhador
        ? JSON.parse(formValues.mensagem_ganhador)
        : []
    );
    usedVirtualMessageGroup.setMessageList(
      formValues.mensagem_grupo ? JSON.parse(formValues.mensagem_grupo) : []
    );
    setSelectedInstancie(
      formValues.instancias ? JSON.parse(formValues.instancias) : []
    );
  }, []);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex gap-2">
        <button
          className={`btn btn-light ${
            selectedConfigSection === "config" ? "btn-dark" : ""
          }`}
          onClick={() => {
            setSelectedConfigSection("config");
          }}
        >
          Configurações
        </button>
        <button
          className={`btn btn-light ${
            selectedConfigSection === "message" ? "btn-dark" : ""
          }`}
          onClick={() => {
            setSelectedConfigSection("message");
          }}
        >
          Mensagens
        </button>
        <button
          className={`btn btn-light ${
            selectedConfigSection === "instancies" ? "btn-dark" : ""
          }`}
          onClick={() => {
            setSelectedConfigSection("instancies");
          }}
        >
          Instancias
        </button>
      </div>

      <div className=" border-1 p-2">
        <div
          id="config"
          className={`flex-column ${
            selectedConfigSection === "config" ? "d-flex" : "d-none"
          }`}
        >
          <div className="d-flex flex-row align-items-end">
            <div className="mb-3 col-8">
              <label className="form-label">Nome:</label>
              <input
                type="text"
                className="form-control"
                defaultValue={formValues.nome}
                onChange={(event) => {
                  setFormData("nome", event.target.value);
                }}
              />
            </div>

            <div className="mb-3 col-4 ps-3">
              <label className="form-label">Importar VIP:</label>
              <input
                type="checkbox"
                className="form-check-input mx-2"
                defaultChecked={formValues.vip_adicionado ? true : false}
                onChange={(event) => {
                  setFormData("vip_adicionado", event.target.checked ? 1 : 0);
                }}
              />
            </div>
          </div>
          <div className="d-flex flex-row gap-4">
            <div className="mb-3 col-3 ">
              <label className="form-label">Data de início:</label>
              <Datetime
                onChange={(value) => {
                  const date = value
                    ? moment(value).format("YYYY-MM-DD HH:mm:ss")
                    : null;
                  setFormData("data_inicio", date);
                }}
                initialValue={
                  formValues.data_inicio
                    ? moment(formValues.data_inicio).format("DD/MM/YYYY HH:mm")
                    : ""
                }
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm"
                locale="pt-br"
              />
            </div>
            <div className="mb-3 col-3">
              <label className="form-label">Data de término:</label>
              <Datetime
                onChange={(value) => {
                  const date = value
                    ? moment(value).format("YYYY-MM-DD HH:mm:ss")
                    : null;
                  setFormData("data_fim", date);
                }}
                initialValue={
                  formValues.data_fim
                    ? moment(formValues.data_fim).format("DD/MM/YYYY HH:mm")
                    : ""
                }
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm"
                locale="pt-br"
              />
            </div>
            <div className="mb-3 col-3">
              <label className="form-label">Qtd ganhadores:</label>
              <input
                type="text"
                className="form-control"
                defaultValue={formValues.qtd_ganhadores}
                onChange={(event) => {
                  setFormData("qtd_ganhadores", event.target.value);
                }}
              />
            </div>
          </div>
          <div className="d-flex flex-column">
            <label>Opções para entrada:</label>
            <OptionsToIngress
              formValues={formValues}
              setFormData={setFormData}
            />
          </div>
        </div>
        <div
          id="msgs"
          className={` flex-column  ${
            selectedConfigSection === "message" ? "d-flex" : "d-none"
          }`}
        >
          <div className="d-flex flex-row gap-2">
            <button
              onClick={() => {
                changeSection("reply");
              }}
              className={`btn ${
                selectedSection === "reply" ? "btn-primary" : "btn-secondary"
              }`}
            >
              Sucesso
            </button>
            <button
              onClick={() => {
                changeSection("double");
              }}
              className={`btn ${
                selectedSection === "double" ? "btn-primary" : "btn-secondary"
              }`}
            >
              Duplicidade
            </button>
            <button
              onClick={() => {
                changeSection("iniciada");
              }}
              className={`btn ${
                selectedSection === "iniciada" ? "btn-primary" : "btn-secondary"
              }`}
            >
              Não iniciada
            </button>
            <button
              onClick={() => {
                changeSection("outdate");
              }}
              className={`btn ${
                selectedSection === "outdate" ? "btn-primary" : "btn-secondary"
              }`}
            >
              Encerrada
            </button>
            <button
              onClick={() => {
                changeSection("winner");
              }}
              className={`btn ${
                selectedSection === "winner" ? "btn-primary" : "btn-secondary"
              }`}
            >
              Ganhador
            </button>
            <button
              onClick={() => {
                changeSection("group");
              }}
              className={`btn ${
                selectedSection === "group" ? "btn-primary" : "btn-secondary"
              }`}
            >
              Grupo
            </button>
          </div>
          <div className="d-flex flex-column">
            {(() => {
              if (selectedSection === "reply") {
                return (
                  <VirtualMessageList
                    flags={["{{Contato}}"]}
                    usedVirtualMessage={usedVirtualMessageReply}
                  />
                );
              }

              if (selectedSection === "double") {
                return (
                  <VirtualMessageList
                    flags={["{{Contato}}"]}
                    usedVirtualMessage={usedVirtualMessageDuplicated}
                  />
                );
              }

              if (selectedSection === "outdate") {
                return (
                  <VirtualMessageList
                    flags={["{{Contato}}"]}
                    usedVirtualMessage={usedVirtualMessageOutDate}
                  />
                );
              }
              if (selectedSection === "iniciada") {
                return (
                  <VirtualMessageList
                    flags={["{{Contato}}"]}
                    usedVirtualMessage={usedVirtualMessageIniciada}
                  />
                );
              }
              if (selectedSection === "winner") {
                return (
                  <VirtualMessageList
                    flags={["{{Contato}}"]}
                    usedVirtualMessage={usedVirtualMessageWinner}
                  />
                );
              }
              if (selectedSection === "group") {
                return (
                  <VirtualMessageList
                    flags={["{{Ganhadores}}", "{{Video}}"]}
                    usedVirtualMessage={usedVirtualMessageGroup}
                  />
                );
              }
            })()}
          </div>
        </div>
        <div
          id="instancies"
          className={` flex flex-wrap ${
            selectedConfigSection === "instancies" ? "d-flex" : "d-none"
          }`}
        >
          {instanciesList.map((instancie) => {
            return (
              <div className="d-flex p-2  col-3">
                <div className="border-1 d-flex p-2 w-100">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      onClick={() => {
                        changeInstancieList(instancie.id);
                      }}
                      checked={
                        !!selectedInstancie.find(
                          (selectInstancie) => selectInstancie === instancie.id
                        )
                      }
                    />
                  </div>
                  {instancie.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const OptionsToIngress: React.FC<IOptionsToIngress> = ({
  formValues,
  setFormData,
}) => {
  const [optionsToIngress, setOptionsToIngress] = useState<Array<string>>([]);
  const [optionInputValue, setOptionInputvalue] = useState("");
  useEffect(() => {
    formValues["chave"] !== "" &&
      setOptionsToIngress(JSON.parse(formValues["chave"]));
    console.log("Form values alterado no optionstoingress");
  }, [formValues]);
  const changeDataInForm = () => {
    setFormData("chave", JSON.stringify(optionsToIngress));
  };
  const addItem = () => {
    if (optionInputValue !== "") {
      setOptionsToIngress((oldValue) => [...oldValue, optionInputValue]);
      setOptionInputvalue("");
    }
    changeDataInForm();
  };
  const delItem = (optionKey: number) => {
    setOptionsToIngress((oldValue) => [
      ...oldValue.filter((value, key) => optionKey !== key),
    ]);
    changeDataInForm();
  };
  return (
    <div className="d-flex border-1 p-2 gap-2 flex-wrap">
      {optionsToIngress.map((option, optionKey) => {
        return (
          <div className="p-2 border-1">
            <span className="mx-2">{option}</span>
            <button
              className="btn btn-sm btn-danger"
              onClick={() => {
                delItem(optionKey);
              }}
            >
              x
            </button>
          </div>
        );
      })}
      <div className="border-1 d-flex px-0 gap-2">
        <input
          type="text"
          className="form-control border-0  ps-2"
          placeholder="Nova entrada"
          value={optionInputValue}
          onChange={(event) => {
            setOptionInputvalue(event.target.value);
          }}
        />
        <button className="btn btn-sm btn-success" onClick={addItem}>
          +
        </button>
      </div>
    </div>
  );
};

const ModalRespostaEnquete: React.FC<IModalRespostaEnquete> = ({
  setFormData,
  formValues,
}) => {
  return (
    <div>
      <div className="mb-3">
        <label className="form-label">Descrição:</label>
        <input
          type="text"
          className="form-control"
          defaultValue={formValues.descricao}
          onChange={(event) => {
            setFormData("descricao", event.target.value);
          }}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Resposta:</label>
        <input
          type="text"
          className="form-control"
          defaultValue={formValues.resposta}
          onChange={(event) => {
            setFormData("resposta", event.target.value);
          }}
        />
      </div>
    </div>
  );
};
