import React, { useEffect, useState } from "react";
import axios, { AxiosResponse, HeadersDefaults } from "axios";
import { api } from "../../Services/api";
import {
  IEnqueteForm,
  IEnqueteListData,
  IEnqueteRespostaForm,
  IRespostaListData,
} from "./types";
import { CONFIG } from "../../../Config";
import { set } from "lodash";

interface CommonHeaderProperties extends HeadersDefaults {
  Authorization: string;
}

export const useEnquetes = () => {
  const [enqueteList, setEnqueteList] = useState<Array<IEnqueteListData>>([]);
  const [loadingList, setLoadingList] = useState(false);
  const [respostasList, setRespostasList] = useState<Array<IRespostaListData>>(
    []
  );
  const [formValues, setFormValues] = useState<IEnqueteForm>({
    nome: "",
    ativo: false,
  });
  useEffect(() => {
    console.log("enquetelist alterada:", enqueteList);
  }, [enqueteList]);
  const duplicateEnquete = (enqueteId: number, callback: () => void) => {
    api.post(`/enquetes/duplicar/${enqueteId}`).then((response) => {
      callback();
      //updateIntanciesList();
      //closeModalInstancie();
    });
  };
  const activeEnquete = (enqueteId: number) => {
    api.put(`/enquetes/ativar/${enqueteId}`).then((response) => {
      console.log(response);
      //updateIntanciesList();
      //closeModalInstancie();
    });
  };

  const desactiveEnquete = (enqueteId: number) => {
    api.put(`/enquetes/ativar/${enqueteId}`).then((response) => {
      console.log(response);
      //updateIntanciesList();
      //closeModalInstancie();
    });
  };
  const [formRespostaValues, setFormRespostaValues] =
    useState<IEnqueteRespostaForm>({ resposta: "", descricao: "" });
  const fillFormDataWithItem = (item: IEnqueteListData) => {
    setFormValues({
      nome: item.nome,
      ativo: item.ativo,
    });
  };
  const fillFormRespostaDataWithItem = (item: IRespostaListData) => {
    setFormRespostaValues({
      resposta: item.resposta,
      descricao: item.descricao,
    });
  };
  const setFormData = (field: keyof IEnqueteForm, value: string | number) => {
    setFormValues((oldValue) => {
      set(oldValue, field, value);
      return oldValue;
    });
  };
  const setFormRespostaData = (
    field: keyof IEnqueteRespostaForm,
    value: string | number
  ) => {
    setFormRespostaValues((oldValue) => {
      set(oldValue, field, value);
      return oldValue;
    });
  };

  const createEnquete = (callback: () => void) => {
    api.post("/enquetes", formValues).then((response) => {
      callback();
      //updateIntanciesList();
      //closeModalInstancie();
    });
  };

  const getEnquete = (
    enqueteId: number,
    callback: (instancieData: IEnqueteListData) => void
  ) => {
    api
      .get(`/enquetes/${enqueteId}`)
      .then(({ data }) => {
        callback(data);
      })
      .catch((error) => console.log("error", error));
  };

  const deleteEnquete = (enqueteId: number, callback: () => void) => {
    api.delete(`/enquetes/${enqueteId}`).then((response) => {
      callback();
      console.log("response", response);
    });
  };

  const deleteEnqueteResposta = (respostaId: number, callback: () => void) => {
    api.delete(`/enquetes/respostas/${respostaId}`).then((response) => {
      callback();
      console.log("response", response);
    });
  };

  const updateEnquete = (
    enqueteId: number,
    callback: () => void,
    data?: IEnqueteListData
  ) => {
    api
      .put(`/enquetes/${enqueteId}`, data ? data : formValues)
      .then((response) => {
        console.log(response);
        callback();
        //updateIntanciesList();
        //closeModalInstancie();
      });
  };
  const updateResposta = (respostaId: number, callback: () => void) => {
    api
      .put(`/enquetes/respostas/${respostaId}`, formRespostaValues)
      .then((response) => {
        callback();
      });
  };
  const updateEnquetesList = async () => {
    setLoadingList(true);
    api
      .get<Array<IEnqueteListData>>("/enquetes")
      .then(({ data }) => {
        setEnqueteList(data);
        setLoadingList(false);
      })
      .catch((error) => console.log("error:", error));
  };
  const updateRespostasList = async (idEnquete: number) => {
    api
      .get<Array<IRespostaListData>>(`/enquetes/${idEnquete}/respostas`)
      .then(({ data }) => {
        setRespostasList(data);
      })
      .catch((error) => console.log("error:", error));
  };
  const createRespostaEnquete = (idEnquete: number, callback: () => void) => {
    api
      .post(`/enquetes/${idEnquete}/respostas`, formRespostaValues)
      .then((response) => {
        setFormRespostaValues({ resposta: "", descricao: "" });
        callback();
        //updateIntanciesList();
        //closeModalInstancie();
      });
  };
  return {
    createRespostaEnquete,
    activeEnquete,
    desactiveEnquete,
    updateRespostasList,
    updateResposta,
    fillFormRespostaDataWithItem,
    setFormRespostaData,
    respostasList,
    enqueteList,
    createEnquete,
    getEnquete,
    deleteEnquete,
    setFormData,
    deleteEnqueteResposta,
    formValues,
    formRespostaValues,
    fillFormDataWithItem,
    updateEnquete,
    updateEnquetesList,
    duplicateEnquete,
    loadingList,
  };
};
