import React, { useEffect, useRef } from "react";
import axios from "axios";
import { useConfirm } from "../../Hooks/Confirm";
import { IModalMessage, useMessage } from "../../Hooks/Message";
import { useModal } from "../../Hooks/Modal";
import { IMessage } from "../../types";
import { useInstancies } from "../Instancies/hook";
import { useBags } from "./hook";
import { CONFIG } from "../../../Config";
import { ModalSender } from "../../Components/ModalSender";
import { MessageList } from "../../Components/MessageList";

export const Birthdays: React.FC = () => {
  const { birthdayList, updateBirthdayList } = useBags();
  const usedMessage = useMessage("birthdays");
  const { updateMessageList } = usedMessage;

  useEffect(() => {
    updateBirthdayList();
    updateMessageList();
  }, []);
  const usedConfirm = useConfirm();
  const { show: openConfirmSend } = usedConfirm;
  const usedConfirmTest = useConfirm();
  const { show: openConfirmSendTest } = usedConfirmTest;
  return (
    <>
      <ModalSender useConfirm={usedConfirm} routeToSend="send-birthday" />
      <ModalSender
        useConfirm={usedConfirmTest}
        routeToSend="send-birthday"
        test
      />

      <div className="card">
        <div className="card-header d-flex flex-column flex-md-row justify-content-between align-items-center">
          <h3>Aniversariantes</h3>
          <div className="d-flex gap-3 my-2 my-md-0">
            <span>
              {birthdayList?.length ? birthdayList?.length : 0} contato(s)
            </span>
            <button
              onClick={updateBirthdayList}
              type="button"
              className="btn btn-sm btn-info"
            >
              atualizar
            </button>
          </div>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={openConfirmSend}
          >
            Enviar Mensagem
          </button>
          <button
            type="button"
            className="btn btn-warning"
            onClick={openConfirmSendTest}
          >
            Enviar teste
          </button>
        </div>
        <MessageList
          flags={["{{Nome}} ", "{{Idade}} ", "{{Contato}}"]}
          usedMessage={usedMessage}
        />
      </div>
    </>
  );
};
