import React, { useEffect } from "react";
import { MessageList } from "../../Components/MessageList";
import { ModalSender } from "../../Components/ModalSender";
import { useConfirm } from "../../Hooks/Confirm";
import { useImport } from "../../Hooks/Import";
import { useMessage } from "../../Hooks/Message";
import { useModal } from "../../Hooks/Modal";
import { useBags } from "./hook";
import { IStatsBags } from "./types";

export const Bags: React.FC = () => {
  const { bagList, updateBaglist } = useBags();
  const usedMessage = useMessage("bags");
  const { updateMessageList } = usedMessage;
  const {
    createImport,
    formValues: formImportValues,
    setFormData: setFormDataImport,
  } = useImport();
  useEffect(() => {
    updateBaglist();
    updateMessageList();
  }, []);
  const {
    Modal: ModalStats,
    show: openModalStats,
    hide: closeModalStats,
  } = useModal();

  const usedConfirm = useConfirm();
  const { show: openConfirmSend } = usedConfirm;
  const usedConfirmTest = useConfirm();
  const { show: openConfirmTest } = usedConfirmTest;
  return (
    <>
      <ModalSender useConfirm={usedConfirm} routeToSend="send-bag" />
      <ModalSender useConfirm={usedConfirmTest} routeToSend="send-bag" test />

      <ModalStats
        title="Estatísticas de envio"
        footer={
          <div className="d-flex gap-3">
            <button
              onClick={closeModalStats}
              type="button"
              className="btn btn-secondary"
            >
              Cancelar
            </button>
          </div>
        }
      >
        <ModalEstatisticas />
      </ModalStats>

      <div className="card">
        <div className="card-header d-flex flex-column flex-md-row justify-content-between align-items-center">
          <h3>Sacolas</h3>
          <div className="d-flex gap-2">
            <span>{bagList?.length ? bagList?.length : 0} contato(s)</span>
            <button
              onClick={updateBaglist}
              type="button"
              className="btn btn-sm btn-info"
            >
              atualizar
            </button>
          </div>
          <div className="d-flex gap-3 my-2 my-md-0">
            <button
              type="button"
              className="btn btn-success"
              onClick={openModalStats}
            >
              Estatísticas
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={openConfirmSend}
            >
              Enviar mensagem
            </button>
            <button
              type="button"
              className="btn btn-warning"
              onClick={openConfirmTest}
            >
              Enviar teste
            </button>
          </div>
        </div>
        <MessageList
          flags={["{{Nome}} ", "{{QtdItens}} ", "{{Contato}}"]}
          usedMessage={usedMessage}
        />
      </div>
    </>
  );
};

const ModalEstatisticas: React.FC = () => {
  const { statsList, updateStatsList } = useBags();
  useEffect(() => {
    updateStatsList();
  }, []);
  return (
    <div className="d-flex flex-column">
      {statsList?.statsAtual ? (
        <ItemEstatisticas data={statsList?.statsAtual} />
      ) : (
        <></>
      )}
      {statsList?.statsAntigo ? (
        statsList?.statsAntigo.map((stats) => <ItemEstatisticas data={stats} />)
      ) : (
        <></>
      )}
    </div>
  );
};

interface IItemEstatisticasProps {
  data: IStatsBags;
}
const ItemEstatisticas: React.FC<IItemEstatisticasProps> = ({ data }) => {
  const newData = data.data
    .replace("00:00:00", "")
    .split("-")
    .reverse()
    .join("/");
  return (
    <div className="d-flex flex-row border-1 p-2 m-2 justify-content-between">
      <div className="d-flex flex-column">
        <b>Data:</b>
        <span>{newData}</span>
      </div>
      <div className="d-flex flex-column align-items-center">
        <b>Enviados:</b>
        <span>{data.total_enviados}</span>
      </div>
      <div className="d-flex flex-column align-items-center">
        <b>Erros:</b>
        <span>{data.total_erros}</span>
      </div>
      <div className="d-flex flex-column align-items-center">
        <b>Duplicados:</b>
        <span>{data.total_duplicados}</span>
      </div>
    </div>
  );
};
