import React, { useEffect } from "react";
import { useConfirm } from "../../Hooks/Confirm";
import { useModal } from "../../Hooks/Modal";
import { useCadastros } from "./hook";
import { IListItemProp, IModalInstancie } from "./types";

export const Cadastros: React.FC = () => {
  const {
    createCadastro,
    setFormData,
    formValues,
    cadastroList,
    updateCadastroList,
  } = useCadastros();

  const {
    Modal,
    show: openModalInstancie,
    hide: closeModalInstancie,
  } = useModal();

  return (
    <>
      <Modal
        title="Criar Instancia"
        footer={
          <div className="d-flex gap-3">
            <button
              type="button"
              onClick={() => {
                createCadastro(() => {
                  updateCadastroList();
                  closeModalInstancie();
                });
              }}
              className="btn btn-success"
            >
              Salvar
            </button>
            <button
              onClick={closeModalInstancie}
              type="button"
              className="btn btn-secondary"
            >
              Cancelar
            </button>
          </div>
        }
      >
        <ModalInstancie setFormData={setFormData} formValues={formValues} />
      </Modal>
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <h3>Instancias</h3>
            {/*  <button
              type="button"
              onClick={openModalInstancie}
              className="btn btn-primary"
            >
              Nova Instancia
            </button> */}
          </div>
        </div>
        <div className="card-body p-2">
          {cadastroList.map((cadastroData, key) => (
            <ListItem
              updateCadastroList={updateCadastroList}
              item={cadastroData}
              key={`ItemCadastroList_${key}`}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const ListItem: React.FC<IListItemProp> = ({ item, updateCadastroList }) => {
  //const { initialized } = useApiInstancie(item.port);
  const {
    Confirm: ConfirmDelete,
    show: openDeleteConfirm,
    hide: closeDeleteConfirm,
  } = useConfirm();

  const {
    Modal,
    show: openModalCadastro,
    hide: closeModalCadastro,
  } = useModal();

  const {
    deleteCadastro,
    updateCadastro,
    setFormData,
    formValues,
    fillFormDataWithItem,
  } = useCadastros();

  useEffect(() => {
    fillFormDataWithItem(item);
  }, [item]);

  return (
    <>
      <ConfirmDelete
        btnLabel="Remover"
        btnColor="danger"
        callback={() => {
          deleteCadastro(item.id, () => {
            updateCadastroList();
            closeDeleteConfirm();
          });
        }}
        title="Deseja realmente remover a instancia?"
      >
        Deseja realmente remover o cadastro? A remoção é permanente.
      </ConfirmDelete>

      <Modal
        title="Editar Instancia"
        footer={
          <div className="d-flex gap-3">
            <button
              type="button"
              onClick={() => {
                updateCadastro(item.id, () => {
                  updateCadastroList();
                  closeModalCadastro();
                });
              }}
              className="btn btn-success"
            >
              Salvar
            </button>
            <button
              onClick={closeModalCadastro}
              type="button"
              className="btn btn-secondary"
            >
              Cancelar
            </button>
          </div>
        }
      >
        <ModalInstancie setFormData={setFormData} formValues={formValues} />
      </Modal>
      <div className="d-flex flex-column flex-md-row border-1 rounded bg-secondary bg-opacity-10 my-3 p-3 justify-content-between">
        <div className="d-flex flex-column flex-md-row">
          <div className="d-flex flex-column mx-3">
            <b>Nome:</b>
            <span>{item.primeiro_nome}</span>
          </div>
          <div className="d-flex flex-column mx-3">
            <b>Telefone:</b>
            <span>{item.telefone}</span>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex flex-row mx-3 gap-2">
            {/* {!item.started && (
              <button
                type="button"
                onClick={openStartInstancieModal}
                className="btn btn-primary"
              >
                Iniciar
              </button>
            )} */}

            <button
              onClick={openModalCadastro}
              type="button"
              className="btn btn-warning"
            >
              Editar
            </button>
            {/* <button
              onClick={openDeleteConfirm}
              type="button"
              className="btn btn-danger"
            >
              Remover
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

const ModalInstancie: React.FC<IModalInstancie> = ({
  setFormData,
  formValues,
}) => {
  return (
    <div>
      <div className="mb-3">
        <label className="form-label">Primeiro Nome:</label>
        <input
          type="text"
          disabled
          className="form-control"
          defaultValue={formValues.primeiro_nome}
          onChange={(event) => {
            setFormData("primeiro_nome", event.target.value);
          }}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Nome Completo:</label>
        <input
          type="text"
          disabled
          className="form-control"
          defaultValue={formValues.nome_completo}
          onChange={(event) => {
            setFormData("nome_completo", event.target.value);
          }}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Telefone:</label>
        <input
          type="text"
          disabled
          className="form-control"
          defaultValue={formValues.telefone}
          onChange={(event) => {
            setFormData("telefone", event.target.value);
          }}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Email:</label>
        <input
          type="text"
          disabled
          className="form-control"
          defaultValue={formValues.email}
          onChange={(event) => {
            setFormData("email", event.target.value);
          }}
        />
      </div>
    </div>
  );
};
