import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "../../../../App/Context/auth";

interface MenuData {
  name: string;
  route: string;
}

export const LayoutMenu = () => {
  const { isAuth } = useAuthContext();
  const MenuData: Array<MenuData> = [
    { name: "Instancias", route: "/" },
    { name: "Sacolas", route: "/sacolas" },
    { name: "Aniversariantes", route: "/aniversariantes" },
    { name: "Publicidade", route: "/publicidade" },
    { name: "Robo", route: "/robo" },
    { name: "Enquetes", route: "/enquetes" },
    { name: "Sorteios", route: "/sorteios" },
    //{ name: "Grupos", route: "/grupos" },
    //{ name: "Cadastros", route: "/cadastros" },
    { name: "Cupons", route: "/cupons" },
  ];
  const { pathname } = useLocation();
  return isAuth ? (
    <div className="d-flex my-4 gap-1 justify-content-between">
      <div>
        {MenuData.map((menuItem, key) => (
          <Link
            key={`ItemMenu_${key}`}
            className={`btn m-1 btn-${
              menuItem.route === pathname ? "primary" : "secondary"
            }`}
            to={menuItem.route}
          >
            {menuItem.name}
          </Link>
        ))}
      </div>
    </div>
  ) : (
    <div className=" my-4"></div>
  );
};
