import { useState } from "react";
import { TFlags } from "../Components/MessageList/types";
import { ISorteioForm } from "../Pages/sorteios/types";

interface IMessageForm {
  message: string;
}
export interface IModalMessage {
  setFormData: (field: keyof IMessageForm, value: string) => void;
  formValues: IMessageForm;
  flags?: Array<TFlags>;
}

export interface IUsedVirtualMessage {
  messageList: Array<string>;
  fillFormDataWithItem: (message: IMessageForm) => void;
  createMessage: (callback: () => void) => void;
  updateMessage: (id: number, callback: () => void) => void;
  deleteMessage: (id: number, callback: () => void) => void;
  setFormData: (field: keyof IMessageForm, value: string) => void;
  formValues: IMessageForm;
  setMessageList: React.Dispatch<React.SetStateAction<string[]>>;
}
interface IUseVirtualMessageProps<T> {
  setFormOutedData: (field: keyof T, value: string | number) => void;
  keyFormValue: keyof T;
}
export function useVirtualMessage<T>({
  setFormOutedData,
  keyFormValue,
}: IUseVirtualMessageProps<T>): IUsedVirtualMessage {
  const [messageList, setMessageList] = useState<Array<string>>([]);

  const [formValues, setFormValues] = useState<IMessageForm>({
    message: "",
  });

  const fillFormDataWithItem = (message: IMessageForm) => {
    setFormValues(message);
  };

  const setFormData = (field: keyof IMessageForm, value: string) => {
    setFormValues((oldValue) => {
      oldValue[field] = value;
      return oldValue;
    });
  };

  const createMessage = (callback: () => void) => {
    setMessageList((oldValue) => {
      const newValue = [...oldValue, formValues.message];
      setFormOutedData(keyFormValue, JSON.stringify(newValue));
      return newValue;
    });

    callback();
  };
  const updateMessage = (key: number, callback: () => void) => {
    setMessageList((oldValue) => {
      const newValue = oldValue.map((value, keyMessage) => {
        return keyMessage === key ? formValues.message : value;
      });
      setFormOutedData(keyFormValue, JSON.stringify(newValue));
      return newValue;
    });
    callback();
  };
  const deleteMessage = (key: number, callback: () => void) => {
    setMessageList((oldValue) => {
      const newValue = oldValue.filter(
        (value, keyMessage) => keyMessage !== key
      );
      setFormOutedData(keyFormValue, JSON.stringify(newValue));
      return newValue;
    });
    callback();
  };
  return {
    messageList,
    formValues,
    setMessageList,
    fillFormDataWithItem,
    setFormData,
    createMessage,
    updateMessage,
    deleteMessage,
  };
}
