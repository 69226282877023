import React, { useEffect, useRef, useState } from "react";
import LoadingSpin from "react-loading-spin";
import { useConfirm } from "../../Hooks/Confirm";
import { useModal } from "../../Hooks/Modal";
import { useInstancies } from "./hook";
import {
  IInstancieListData,
  IListItemProp,
  IModalInstancie,
  IQrCodeInstancieData,
} from "./types";
import { set } from "lodash";
import { api } from "../../Services/api";
import { useApiInstancie } from "../../Hooks/ApiInstancie";
import { useInstancieContext } from "../../Context/instancie";
import { useSocketContext } from "../../Context/socket";
import {
  ISocketQrCodeData,
  ISocketQrCodeStatusData,
  TWhatsappStatusSession,
} from "../../types";

export const Instancies: React.FC = () => {
  const { createInstancie, setFormData, formValues } = useInstancies();

  const { instanciesList, updateIntanciesList } = useInstancieContext();

  const {
    Modal,
    show: openModalInstancie,
    hide: closeModalInstancie,
  } = useModal();

  return (
    <>
      <Modal
        title="Criar Instancia"
        footer={
          <div className="d-flex gap-3">
            <button
              type="button"
              onClick={() => {
                createInstancie(() => {
                  updateIntanciesList();
                  closeModalInstancie();
                });
              }}
              className="btn btn-success"
            >
              Salvar
            </button>
            <button
              onClick={closeModalInstancie}
              type="button"
              className="btn btn-secondary"
            >
              Cancelar
            </button>
          </div>
        }
      >
        <ModalInstancie setFormData={setFormData} formValues={formValues} />
      </Modal>
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <h3>Instancias</h3>
            {/*  <button
              type="button"
              onClick={openModalInstancie}
              className="btn btn-primary"
            >
              Nova Instancia
            </button> */}
          </div>
        </div>
        <div className="card-body p-2">
          {instanciesList.map((instancieData, key) => (
            <ListItem
              updateIntanciesList={updateIntanciesList}
              item={instancieData}
              key={`ItemInstancieList_${key}`}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const ListItem: React.FC<IListItemProp> = ({ item, updateIntanciesList }) => {
  //const { initialized } = useApiInstancie(item.port);
  const {
    Confirm: ConfirmDelete,
    show: openDeleteConfirm,
    hide: closeDeleteConfirm,
  } = useConfirm();

  const {
    Modal,
    show: openModalInstancie,
    hide: closeModalInstancie,
  } = useModal();

  const {
    Modal: StartInstancieModal,
    show: openStartInstancieModal,
    hide: closeStartInstancieModal,
  } = useModal({
    onClose: () => {
      window.location.reload();
    },
  });
  const {
    deleteInstancie,
    updateInstancie,
    setFormData,
    formValues,
    fillFormDataWithItem,
  } = useInstancies();

  useEffect(() => {
    fillFormDataWithItem(item);
  }, [item]);

  return (
    <>
      <ConfirmDelete
        btnLabel="Remover"
        btnColor="danger"
        callback={() => {
          deleteInstancie(item.id, () => {
            updateIntanciesList();
            closeDeleteConfirm();
          });
        }}
        title="Deseja realmente remover a instancia?"
      >
        Deseja realmente remover a instancia? A remoção é permanente.
      </ConfirmDelete>
      <StartInstancieModal size="sm" hideClose title={`Iniciar ${item.name}`}>
        <ModalStartInstancie
          item={item}
          closeModal={closeStartInstancieModal}
        />
      </StartInstancieModal>
      <Modal
        title="Editar Instancia"
        footer={
          <div className="d-flex gap-3">
            <button
              type="button"
              onClick={() => {
                updateInstancie(item.id, () => {
                  updateIntanciesList();
                  closeModalInstancie();
                });
              }}
              className="btn btn-success"
            >
              Salvar
            </button>
            <button
              onClick={closeModalInstancie}
              type="button"
              className="btn btn-secondary"
            >
              Cancelar
            </button>
          </div>
        }
      >
        <ModalInstancie setFormData={setFormData} formValues={formValues} />
      </Modal>
      <div className="d-flex flex-column flex-md-row border-1 rounded bg-secondary bg-opacity-10 my-3 p-3 justify-content-between">
        <div className="d-flex flex-column flex-md-row">
          <div className="d-flex flex-column mx-3">
            <b>Nome:</b>
            <span>{item.name}</span>
          </div>
          <div className="d-flex flex-column mx-3">
            <b>Porta:</b>
            <span>{item.port}</span>
          </div>
          <div className="d-flex flex-column mx-3">
            <b>Status:</b>
            <span>{item.started ? "Iniciado" : "Parado"}</span>
          </div>
          <div className="d-flex flex-column mx-3">
            <b>Resposta automática:</b>
            <span>{item.robo ? "Ativado" : "Desativado"}</span>
          </div>
          <div className="d-flex flex-column mx-3">
            <b>Permitir envio:</b>
            <span>{item.apenas_robo ? "Não" : "Sim"}</span>
          </div>
          <div className="d-flex flex-column mx-3">
            <b>Enquete:</b>
            <span>{!item.enquete ? "Não" : "Sim"}</span>
          </div>
          <div className="d-flex flex-column mx-3">
            <b>Sorteio:</b>
            <span>{!item.sorteio ? "Não" : "Sim"}</span>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex flex-row mx-3 gap-2">
            {!item.started && (
              <button
                type="button"
                onClick={openStartInstancieModal}
                className="btn btn-primary"
              >
                Iniciar
              </button>
            )}

            <button
              onClick={openModalInstancie}
              type="button"
              className="btn btn-warning"
            >
              Editar
            </button>
            {/* <button
              onClick={openDeleteConfirm}
              type="button"
              className="btn btn-danger"
            >
              Remover
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

const ModalInstancie: React.FC<IModalInstancie> = ({
  setFormData,
  formValues,
}) => {
  return (
    <div>
      <div className="mb-3">
        <label className="form-label">Nome:</label>
        <input
          type="text"
          disabled
          className="form-control"
          defaultValue={formValues.name}
          onChange={(event) => {
            setFormData("name", event.target.value);
          }}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Porta:</label>
        <input
          type="text"
          disabled
          className="form-control"
          defaultValue={formValues.port}
          onChange={(event) => {
            setFormData("port", event.target.value);
          }}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Resposta automática:</label>
        <input
          type="checkbox"
          className="form-check-input mx-2"
          defaultChecked={formValues.robo}
          onChange={(event) => {
            setFormData("robo", event.target.checked ? 1 : 0);
          }}
        />
      </div>
      <div className="mb-3">
        <label className="form-labe">Permitir envio:</label>
        <input
          type="checkbox"
          className="form-check-inputl mx-2"
          defaultChecked={!formValues.apenas_robo}
          onChange={(event) => {
            setFormData("apenas_robo", event.target.checked ? 0 : 1);
          }}
        />
      </div>
      <div className="mb-3">
        <label className="form-labe">Enquete:</label>
        <input
          type="checkbox"
          className="form-check-inputl mx-2"
          defaultChecked={formValues.enquete}
          onChange={(event) => {
            setFormData("enquete", event.target.checked ? 1 : 0);
          }}
        />
      </div>
      <div className="mb-3">
        <label className="form-labe">Sorteio:</label>
        <input
          type="checkbox"
          className="form-check-inputl mx-2"
          defaultChecked={formValues.sorteio}
          onChange={(event) => {
            setFormData("sorteio", event.target.checked ? 1 : 0);
          }}
        />
      </div>
    </div>
  );
};

interface IModalStartInstancie {
  item: IInstancieListData;
  closeModal: () => void;
}
const ModalStartInstancie: React.FC<IModalStartInstancie> = ({
  item,
  closeModal,
}) => {
  const { updateIntanciesList } = useInstancieContext();
  const { initInstancie } = useInstancies();

  const [socketQrCodeData, setSocketQrCodeData] = useState<ISocketQrCodeData>({
    asciiQR: "",
    attempts: 0,
    base64Qr: "",
    instancieId: item.id.toString(),
    urlCode: "",
  });
  const [socketQrCodeStatusData, setSocketQrCodeStatusData] =
    useState<ISocketQrCodeStatusData>({
      instancieId: item.id.toString(),
      statusSession: "initServer",
    });

  const { socket } = useSocketContext();

  useEffect(() => {
    if (socket) {
      socket.on("qrcode", (data: ISocketQrCodeData): void => {
        setSocketQrCodeData(data);
        console.log("Data de atualizacao do qrcode:", data);
      });
      socket.on("status", (data: ISocketQrCodeStatusData): void => {
        setSocketQrCodeStatusData(data);
        console.log("Data de atualizacao do status:", data);
      });
    }
  }, [socket]);

  const statusLoadingable: TWhatsappStatusSession[] = [
    "initBrowser",
    "initServer",
    "openBrowser",
    "qrReadSuccess",
    "successPageWhatsapp",
    "initWhatsapp",
    "waitChat",
    "waitForLogin",
  ];
  const statusError: TWhatsappStatusSession[] = ["qrReadFail"];
  const statusSuccess: TWhatsappStatusSession[] = ["successChat"];
  const statusCloseable: TWhatsappStatusSession[] = [
    "qrReadFail",
    "successChat",
  ];
  const statusDescrible = {
    initServer: "Iniciando Servidor",
    notLogged: "Aguardando leitura do QrCode",
    initBrowser: "Iniciando Navegador",
    openBrowser: "Abrindo Navegador",
    initWhatsapp: "Iniciando Whatsapp",
    successPageWhatsapp: "Aguardando geração do QrCode",
    qrReadSuccess: "QrCode lido com sucesso",
    waitForLogin: "Aguardando autenticação",
    waitChat: "Lendo conversas",
    successChat: "Whatsapp iniciado com sucesso",
    qrReadFail: "Falha ao ler QrCode",
  };

  //useEffect(() => {
  //  getInstancie(item.id, (instancieData) => {
  //    setQrCodeData(JSON.parse(instancieData.qrCode));
  //    console.log(instancieData);
  //  });
  //}, []);

  //useEffect(() => {
  //  instancieData && setQrCodeData(JSON.parse(instancieData.qrCode));
  //}, [instancieData]);

  useEffect(() => {
    initInstancie(item.id);
    //const interval = setInterval(() => {
    //  getInstancie(item.id, (instancieData) => {
    //    if (
    //      !["initWhatsapp", "notLogged", "successPageWhatsapp"].includes(
    //        instancieData.wp_status
    //      )
    //    ) {
    //      clearInterval(interval);
    //    }
    //    setInstancieData(instancieData);
    //    console.log(instancieData);
    //  });
    //}, 5000);
  }, []);

  return (
    <div className="d-flex flex-column align-items-center">
      {socketQrCodeStatusData.statusSession === "notLogged" ? (
        <>
          <img src={socketQrCodeData?.base64Qr} />
          <h5>Tentativa:{socketQrCodeData?.attempts} de 6</h5>
        </>
      ) : (
        <></>
      )}
      {statusLoadingable.includes(socketQrCodeStatusData.statusSession) ? (
        <LoadingSpin size={"264px"} />
      ) : (
        <></>
      )}
      {statusError.includes(socketQrCodeStatusData.statusSession) ? (
        <div
          style={{ width: "264px", height: "264px" }}
          className="d-flex justify-content-center align-items-center rounded"
        >
          <div className="rounded-circle px-5 py-4 bg-danger text-white">
            <h1>X</h1>
          </div>
        </div>
      ) : (
        <></>
      )}
      {statusSuccess.includes(socketQrCodeStatusData.statusSession) ? (
        <div
          style={{ width: "264px", height: "264px" }}
          className="d-flex justify-content-center align-items-center rounded"
        >
          <div className="rounded-circle p-5 bg-success text-white">
            <h1>OK</h1>
          </div>
        </div>
      ) : (
        <></>
      )}
      <h5 className="my-2">
        {statusDescrible[socketQrCodeStatusData?.statusSession]}
      </h5>
      {statusCloseable.includes(socketQrCodeStatusData.statusSession) ? (
        <button
          onClick={closeModal}
          type="button"
          className="btn btn-danger p-3"
        >
          Fechar
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};
