import React, { useEffect, useRef } from "react";
import { IModalMessage, IUsedMessage } from "../../Hooks/Message";
import { useConfirm } from "../../Hooks/Confirm";
import { IMessage } from "../../types";
import { useModal } from "../../Hooks/Modal";
import { IMessageList, TFlags } from "./types";

export const MessageList: React.FC<IMessageList> = ({ usedMessage, flags }) => {
  const {
    messageList,
    updateMessageList,
    createMessage,
    setFormData,
    formValues,
  } = usedMessage;

  const { Modal, show: openModalMessage, hide: closeModalMessage } = useModal();
  return (
    <>
      <Modal
        title="Nova Mensagem"
        footer={
          <div className="d-flex gap-3">
            <button
              type="button"
              onClick={() => {
                createMessage(() => {
                  updateMessageList();
                  setFormData("message", "");
                  closeModalMessage();
                });
              }}
              className="btn btn-success"
            >
              Salvar
            </button>
            <button
              onClick={closeModalMessage}
              type="button"
              className="btn btn-secondary"
            >
              Cancelar
            </button>
          </div>
        }
      >
        <ModalMessage
          flags={flags}
          setFormData={setFormData}
          formValues={formValues}
        />
      </Modal>

      <div className="card-body d-flex flex-column gap-3">
        {messageList?.length ? (
          messageList.map((message) => {
            return (
              <MessageItem
                flags={flags}
                usedMessage={usedMessage}
                message={message}
                key={`MessageList_${message.id}`}
              />
            );
          })
        ) : (
          <h5>Nenhuma mensagem para ser exibida</h5>
        )}
        <button
          type="button"
          className="btn btn-success btn-sm"
          onClick={() => {
            setFormData("message", "");
            openModalMessage();
          }}
        >
          Nova Mensagem
        </button>
      </div>
    </>
  );
};

interface MessageItem {
  message: IMessage;
  usedMessage: IUsedMessage;
  flags?: Array<TFlags>;
}
const MessageItem: React.FC<MessageItem> = ({
  message,
  usedMessage,
  flags,
}) => {
  const {
    fillFormDataWithItem,
    deleteMessage,
    updateMessageList,
    updateMessage,
    setFormData,
    formValues,
  } = usedMessage;
  const { Modal, show: openModalMessage, hide: closeModalMessage } = useModal();
  const {
    Confirm: ConfirmDelete,
    show: openDeleteConfirm,
    hide: closeDeleteConfirm,
  } = useConfirm();
  useEffect(() => {
    fillFormDataWithItem(message);
  }, []);
  return (
    <>
      <ConfirmDelete
        btnLabel="Remover"
        btnColor="danger"
        callback={() => {
          deleteMessage(message.id, () => {
            updateMessageList();
            closeDeleteConfirm();
          });
        }}
        title="Deseja realmente remover a mensagem?"
      >
        Deseja realmente remover a mensagem? A remoção é permanente.
      </ConfirmDelete>
      <Modal
        title="Editar Mensagem"
        footer={
          <div className="d-flex gap-3">
            <button
              type="button"
              onClick={() => {
                updateMessage(message.id, () => {
                  updateMessageList();
                  closeModalMessage();
                });
              }}
              className="btn btn-success"
            >
              Salvar
            </button>
            <button
              onClick={closeModalMessage}
              type="button"
              className="btn btn-secondary"
            >
              Cancelar
            </button>
          </div>
        }
      >
        <ModalMessage
          flags={flags}
          setFormData={setFormData}
          formValues={formValues}
        />
      </Modal>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center border p-2 gap-3 w-100">
        <div
          style={{ whiteSpace: "pre-wrap" }}
          className="p-3 border rounded w-100"
        >
          {message.message}{" "}
        </div>
        <div className="d-flex gap-3">
          <button
            type="button"
            onClick={() => {
              setFormData("message", message.message);
              openModalMessage();
            }}
            className="btn btn-sm btn-warning"
          >
            Editar
          </button>
          <button
            type="button"
            onClick={openDeleteConfirm}
            className="btn btn-sm btn-danger"
          >
            Remover
          </button>
        </div>
      </div>
    </>
  );
};

const ModalMessage: React.FC<IModalMessage> = ({
  setFormData,
  formValues,
  flags,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const addFlagToTextArea = (textToInsert: string) => {
    if (textAreaRef.current !== null) {
      let cursorPosition = textAreaRef.current.selectionStart;
      let textBeforeCursorPosition = textAreaRef.current.value.substring(
        0,
        cursorPosition
      );
      let textAfterCursorPosition = textAreaRef.current.value.substring(
        cursorPosition,
        textAreaRef.current.value.length
      );
      textAreaRef.current.value =
        textBeforeCursorPosition + textToInsert + textAfterCursorPosition;
      setFormData("message", textAreaRef.current.value);
    }
  };
  return (
    <div>
      Mensagem:
      <textarea
        ref={textAreaRef}
        className="input-group border rounded"
        style={{ height: "200px" }}
        defaultValue={formValues.message}
        onChange={(event) => {
          setFormData("message", event.currentTarget.value);
        }}
      ></textarea>
      <div className="d-flex gap-2 my-2">
        {flags?.map((flag) => {
          return (
            <button
              type="button"
              onClick={() => {
                addFlagToTextArea(flag);
              }}
            >
              {flag}
            </button>
          );
        })}
      </div>
    </div>
  );
};
