import React, { useState } from "react";
import styled from "styled-components";

const ContainerModal = styled.div`
  display: flex;
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
const BackgroundModal = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
`;
interface IModalContentProps {
  width: string;
}
const ModalContent = styled.div<IModalContentProps>`
  width: ${(prop) => prop.width};
  max-height: 880px;
  height: 100%;
  overflow: scroll;
`;
interface ModalProps {
  title: string;
  zIndex?: number;
  children: React.ReactNode;
  footer?: JSX.Element;
  size?: sizeOfModal;
  hideClose?: boolean;
}
interface IUseModalProps {
  onClose?: () => void;
}
type sizeOfModal = "xsm" | "sm" | "md" | "lg" | "xlg";
export const useModal = (props?: IUseModalProps) => {
  const [visible, setVisible] = useState(false);
  const show = () => {
    setVisible(true);
  };
  const hide = () => {
    setVisible(false);
    console.log("validando se há callback");
    if (props?.onClose) {
      console.log("ha callback");
      props.onClose();
    }
  };
  const Modal: React.FC<ModalProps> = ({
    children,
    title,
    footer,
    zIndex = 5,
    size = "lg",
    hideClose = false,
  }) => {
    const getWidthBySize = () => {
      const sizes = {
        xsm: "200px",
        sm: "350px",
        md: "500px",
        lg: "800px",
        xlg: "100%",
      };
      return sizes[size];
    };
    return visible ? (
      <ContainerModal style={{ zIndex: zIndex }}>
        <BackgroundModal />
        <ModalContent width={getWidthBySize()}>
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <h3>{title}</h3>
              {!hideClose ? (
                <button
                  onClick={hide}
                  type="button"
                  className="btn btn-sm btn-danger"
                >
                  X
                </button>
              ) : (
                <></>
              )}
            </div>
            <div className="card-body">{children}</div>
            {footer && <div className="card-footer">{footer}</div>}
          </div>
        </ModalContent>
      </ContainerModal>
    ) : (
      <></>
    );
  };
  return { Modal, show, hide, visible };
};
