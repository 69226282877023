import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { IInstancieListData } from "../Pages/Instancies/types";
import { useInstancies } from "../Pages/Instancies/hook";
import { update } from "lodash";
import { useSocketContext } from "./socket";
import { ISocketQrCodeData, ISocketQrCodeStatusData } from "../types";

interface IInstancieContext {
  updateIntanciesList: () => void;
  instanciesList: Array<IInstancieListData>;
}

const InstancieContext = createContext<IInstancieContext>(
  {} as IInstancieContext
);

export const InstancieProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { instanciesList, updateIntanciesList } = useInstancies();

  useEffect(() => {
    updateIntanciesList();
  }, []);

  useEffect(() => {}, [instanciesList]);

  return (
    <InstancieContext.Provider
      value={{
        updateIntanciesList,
        instanciesList,
      }}
    >
      {children}
    </InstancieContext.Provider>
  );
};

export const useInstancieContext = () => {
  const context = useContext(InstancieContext);
  return context;
};
