import React, { useState } from "react";
import { api } from "../../Services/api";
import { IBagList } from "./types";

export interface IUsedPublicity {
  publicityList: IBagList[];
  updatePublicitylist: () => void;
  updatePublicityCount: () => void;
  publicityCount: number;
  importPublicityList: () => void;
  switchSending: () => void;
  isSending: boolean;
  getStatusSend: () => void;
  stopSend: () => void;
  changeNumbersToBlock: (newValue: string) => void;
  addNumbersToBlacklist: (callback: () => void) => void;
}

export const usePublicity = (): IUsedPublicity => {
  const [publicityList, setPublicityList] = useState<Array<IBagList>>([]);
  const [publicityCount, setPublicityCount] = useState<number>(0);
  const [isSending, setIsSending] = useState(false);
  const [numbersToBlock, setNumbersToBlock] = useState("");

  const updatePublicitylist = () => {
    api.get<Array<IBagList>>("/publicity/pending").then(({ data }) => {
      setPublicityList(data);
    });
  };

  const changeNumbersToBlock = (newValue: string) => {
    setNumbersToBlock(newValue);
  };

  const updatePublicityCount = () => {
    api.get<number>("/publicity/pending").then(({ data }) => {
      setPublicityCount(data);
    });
  };

  const importPublicityList = () => {
    api.get("/publicity/import").then(({ data }) => {
      updatePublicitylist();
      console.log(data);
    });
  };

  const getStatusSend = () => {
    api.get<boolean>("publicity/status").then(({ data }) => {
      setIsSending(data);
    });
  };
  const stopSend = () => {
    api.get<boolean>("publicity/stop").then(({ data }) => {
      console.log(data);
      switchSending();
    });
  };
  const switchSending = () => {
    setIsSending((oldValue) => !oldValue);
  };

  const addNumbersToBlacklist = (callback: () => void) => {
    api.post("publicity/blacklist", { numbers: numbersToBlock }).then(() => {
      callback();
    });
  };

  return {
    publicityList,
    updatePublicitylist,
    updatePublicityCount,
    publicityCount,
    importPublicityList,
    switchSending,
    isSending,
    getStatusSend,
    stopSend,
    changeNumbersToBlock,
    addNumbersToBlacklist,
  };
};
