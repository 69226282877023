import React, { useState } from "react";
import { api } from "../../Services/api";
import { IBirthdayList } from "./types";

export const useBags = () => {
  const [birthdayList, setBirthdayList] = useState<Array<IBirthdayList>>();

  const updateBirthdayList = () => {
    api.get<Array<IBirthdayList>>("/birthdays/pending").then(({ data }) => {
      setBirthdayList(data);
    });
  };
  return { birthdayList, updateBirthdayList };
};
