import React, { useEffect, useState } from "react";
import axios, { AxiosResponse, HeadersDefaults } from "axios";
import { api } from "../../Services/api";
import { ICadastroForm, ICadastroListData } from "./types";
import { CONFIG } from "../../../Config";
import { set } from "lodash";

interface CommonHeaderProperties extends HeadersDefaults {
  Authorization: string;
}

export const useCadastros = () => {
  const [cadastroList, setCadastroList] = useState<Array<ICadastroListData>>(
    []
  );
  const [formValues, setFormValues] = useState<ICadastroForm>({
    id_fonte: 0,
    id_natura: 0,
    primeiro_nome: "",
    nome_completo: "",
    data_nascimento: "",
    telefone: "",
    email: "",
    sexo: "",
    estado: "",
  });
  const fillFormDataWithItem = (item: ICadastroListData) => {
    setFormValues({
      id_fonte: item.id_fonte,
      id_natura: item.id_natura,
      primeiro_nome: item.primeiro_nome,
      nome_completo: item.nome_completo,
      data_nascimento: item.data_nascimento,
      telefone: item.telefone,
      email: item.email,
      sexo: item.sexo,
      estado: item.estado,
    });
  };
  const setFormData = (field: keyof ICadastroForm, value: string | number) => {
    setFormValues((oldValue) => {
      set(oldValue, field, value);
      return oldValue;
    });
  };

  const createCadastro = (callback: () => void) => {
    api.post("/instancies", formValues).then((response) => {
      callback();
      //updateIntanciesList();
      //closeModalInstancie();
    });
  };

  const getCadastro = (
    instancieId: number,
    callback: (instancieData: ICadastroListData) => void
  ) => {
    api
      .get(`/instancies/${instancieId}`)
      .then(({ data }) => {
        callback(data);
      })
      .catch((error) => console.log("error", error));
  };

  const deleteCadastro = (instancieId: number, callback: () => void) => {
    api.delete(`/instancies/${instancieId}`).then((response) => {
      callback();
      console.log("response", response);
      //updateIntanciesList();
      //closeDeleteConfirm();
    });
  };

  const updateCadastro = (instancieId: number, callback: () => void) => {
    api.put(`/instancies/${instancieId}`, formValues).then((response) => {
      callback();
      //updateIntanciesList();
      //closeModalInstancie();
    });
  };

  const updateCadastroList = async () => {
    api
      .get<Array<ICadastroListData>>("/instancies")
      .then(async ({ data }) => {
        setCadastroList(data);
      })
      .catch((error) => console.log("error:", error));
  };

  return {
    cadastroList,
    updateCadastroList,
    createCadastro,
    deleteCadastro,
    updateCadastro,
    setFormData,
    formValues,
    fillFormDataWithItem,
    getCadastro,
  };
};
