import React, { useState } from "react";
import { api } from "../Services/api";

interface IImportForm {
  data: string;
}

export const useImport = () => {
  const [formValues, setFormValues] = useState<IImportForm>({
    data: "",
  });

  const setFormData = (field: keyof IImportForm, value: string) => {
    setFormValues((oldValue) => {
      oldValue[field] = value;
      return oldValue;
    });
  };

  const createImport = (callback: () => void) => {
    api.post("/import", formValues).then(() => {
      callback();
    });
  };
  return {
    createImport,
    formValues,
    setFormData,
  };
};
