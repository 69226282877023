import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { IInstancieListData } from "../Pages/Instancies/types";
import { useInstancies } from "../Pages/Instancies/hook";
import { update } from "lodash";

interface IAuthContext {
  isAuth: boolean;
  auth: (userName: string, password: string) => void;
  error: boolean;
  logout: () => void;
}
interface IAuthDataLocalStorage {
  userName: string;
  password: string;
}
const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    const authData = localStorage.getItem("authData");
    if (authData) {
      const jsonAuthData = JSON.parse(authData) as IAuthDataLocalStorage;
      auth(jsonAuthData.userName, jsonAuthData.password);
    }
  }, []);
  const auth = (userName: string, password: string) => {
    if (userName === "admin" && password === "Mc010101.") {
      setIsAuth(true);
      setError(false);
      localStorage.setItem("authData", JSON.stringify({ userName, password }));
      return;
    }
    setError(true);
  };
  const logout = () => {
    localStorage.clear();
    setIsAuth(false);
  };

  return (
    <AuthContext.Provider value={{ isAuth, auth, error, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  return context;
};
