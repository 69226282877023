import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MessageList } from "../../Components/MessageList";
import { ModalSender } from "../../Components/ModalSender";
import { useConfirm } from "../../Hooks/Confirm";
import { useMessage } from "../../Hooks/Message";
import { IUsedPublicity, usePublicity } from "./hook";
import { useModal } from "../../Hooks/Modal";

export const Publicity: React.FC = () => {
  const usedPublicity = usePublicity();
  const {
    publicityCount,
    updatePublicityCount,
    importPublicityList,
    getStatusSend,
    isSending,
    stopSend,
    switchSending,
  } = usedPublicity;
  const usedMessage = useMessage("publicity");
  const { updateMessageList } = usedMessage;
  useEffect(() => {
    //updatePublicityCountInterval();
    updatePublicityCount();
    updateMessageList();
    getStatusSend();
  }, []);

  // useEffect(() => {
  //   const timeout = setInterval(updatePublicityCount, 10000);
  //   return () => {
  //     clearInterval(timeout);
  //   };
  // });

  //useEffect(() => {
  //  if (isSending) {
  //    updatePublicityCountInterval();
  //  } else {
  //    clearTimeout(timeout);
  //  }
  //}, [isSending]);

  //const updatePublicityCountInterval = () => {
  //  updatePublicityCount();
  //  timeout = setTimeout(updatePublicityCountInterval, 10000);
  //};

  const {
    Confirm: ConfirmImport,
    show: openImportConfirm,
    hide: closeImportConfirm,
  } = useConfirm();

  const usedConfirm = useConfirm();
  const usedConfirmTest = useConfirm();
  const { show: openConfirmSend } = usedConfirm;
  const { show: openConfirmTest } = usedConfirmTest;
  const {
    Modal: ModalAddBlackList,
    show: showBlackList,
    hide: hideBlackList,
  } = useModal();
  return (
    <>
      <ModalAddBlackList title="Adicionar Contatos na Blacklist">
        <AddBlacklist hideBlackList={hideBlackList} />
      </ModalAddBlackList>
      <ModalSender
        useConfirm={usedConfirm}
        routeToSend="send-publicity"
        callbackAtSend={() => {
          switchSending();
        }}
      />
      <ModalSender
        useConfirm={usedConfirmTest}
        routeToSend="send-publicity"
        test
        callbackAtSend={() => {
          switchSending();
        }}
      />
      <ConfirmImport
        btnLabel="Importar"
        btnColor="success"
        callback={() => {
          importPublicityList();
          closeImportConfirm();
        }}
        title="Importação de contatos"
      >
        Deseja realmente importar os contatos da lista vip para serem usados na
        publicidade?
      </ConfirmImport>
      <div className="card">
        <div className="card-header d-flex flex-column flex-md-row justify-content-between align-items-center">
          <h3>Publicidade</h3>
          <div className="d-flex gap-2">
            <span>{publicityCount.toString()} contato(s)</span>
            <button
              onClick={updatePublicityCount}
              type="button"
              className="btn btn-sm btn-info"
            >
              atualizar
            </button>
            <button
              onClick={openImportConfirm}
              type="button"
              className="btn btn-sm btn-success"
            >
              importar
            </button>

            <button
              onClick={showBlackList}
              type="button"
              className="btn btn-sm btn-dark"
            >
              add blacklist
            </button>
          </div>
          <div className="d-flex gap-3 my-2 my-md-0">
            {!isSending ? (
              <>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={openConfirmSend}
                >
                  Enviar mensagem
                </button>
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={openConfirmTest}
                >
                  Enviar teste
                </button>
              </>
            ) : (
              <button
                type="button"
                className="btn btn-danger"
                onClick={stopSend}
              >
                Parar envio
              </button>
            )}
          </div>
        </div>
        <MessageList
          flags={[
            "{{Nome}} ",
            "{{QtdItens}} ",
            "{{DDMM-ANIVER}} ",
            "{{Contato}}",
          ]}
          usedMessage={usedMessage}
        />
      </div>
    </>
  );
};

interface IAddBlackList {
  hideBlackList: () => void;
}

const AddBlacklist: React.FC<IAddBlackList> = ({ hideBlackList }) => {
  const { changeNumbersToBlock, addNumbersToBlacklist } = usePublicity();
  return (
    <div className="d-flex flex-column">
      <p>
        Adicione no campo abaixo os números que serão bloqueados no formato
        DDXXXXXXXXX separados por ;
      </p>
      <p>
        <b>Exemplo:</b>
        <br />
        61998542010;61996961012;61996057398
      </p>
      <p>
        <b>Números:</b>
        <br />
        <textarea
          onChange={(event) => {
            changeNumbersToBlock(event.currentTarget.value);
          }}
          className="border w-100"
          style={{ height: "100px" }}
        ></textarea>
      </p>
      <div className="d-flex justify-content-between">
        <button
          className="btn btn-primary btn-sm"
          onClick={() => {
            addNumbersToBlacklist(() => {
              hideBlackList();
              alert("Numeros adicionados na blacklist");
            });
          }}
        >
          Salvar
        </button>
        <button className="btn btn-light btn-sm" onClick={hideBlackList}>
          Cancelar
        </button>
      </div>
    </div>
  );
};
