import React from "react";
import { Route } from "react-router-dom";
import { AuthProvider, useAuthContext } from "./App/Context/auth";
import { InstancieProvider } from "./App/Context/instancie";
import { Bags } from "./App/Pages/Bags";
import { Birthdays } from "./App/Pages/Birthday";
import { BotMessage } from "./App/Pages/Bot";
import { Instancies } from "./App/Pages/Instancies";
import { Login } from "./App/Pages/Login";
import { Publicity } from "./App/Pages/Publicity";
import { Enquetes } from "./App/Pages/enquetes";
import { LayoutDefault } from "./Layout/default";
import "./style.css";
import { Sorteios } from "./App/Pages/sorteios";
import { Grupos } from "./App/Pages/grupos";
import { Cadastros } from "./App/Pages/Cadastros";
import { Cupons } from "./App/Pages/cupons";
import { SocketProvider } from "./App/Context/socket";

function App() {
  return (
    <SocketProvider>
      <AuthProvider>
        <InstancieProvider>
          <LayoutDefault>
            <RouteComponent component={Instancies} path="/" exact />
            <RouteComponent component={Instancies} path="/instancias" />
            <RouteComponent
              component={Birthdays}
              path="/aniversariantes"
              exact
            />
            <RouteComponent component={Bags} path="/sacolas" exact />
            <RouteComponent component={Publicity} path="/publicidade" exact />
            <RouteComponent component={BotMessage} path="/robo" exact />
            <RouteComponent component={Enquetes} path="/enquetes" exact />
            <RouteComponent component={Sorteios} path="/sorteios" exact />
            <RouteComponent component={Grupos} path="/grupos" exact />
            <RouteComponent component={Cadastros} path="/grupos" exact />
            <RouteComponent component={Cupons} path="/cupons" exact />
          </LayoutDefault>
        </InstancieProvider>
      </AuthProvider>
    </SocketProvider>
  );
}
interface IRouteComponent {
  component: React.FC;
  path: string;
  exact?: boolean;
}
const RouteComponent: React.FC<IRouteComponent> = ({
  component,
  path,
  exact = false,
}) => {
  const { isAuth } = useAuthContext();
  return (
    <Route component={isAuth ? component : Login} path={path} exact={exact} />
  );
};

export default App;
