import { HeadersDefaults } from "axios";
import { set } from "lodash";
import { useState } from "react";
import { api } from "../../Services/api";
import {
  IEnqueteRespostaForm,
  IRespostaListData,
  ISorteioForm,
  ISorteioListData,
} from "./types";

interface CommonHeaderProperties extends HeadersDefaults {
  Authorization: string;
}

export const useSorteios = () => {
  const [sorteioList, setSorteioList] = useState<Array<ISorteioListData>>([]);
  const [loadingList, setLoadingList] = useState(false);
  const [respostasList, setRespostasList] = useState<Array<IRespostaListData>>(
    []
  );
  const [formValues, setFormValues] = useState<ISorteioForm>({
    nome: "",
    ativo: 0,
    chave: "",
    data_fim: "",
    data_inicio: "",
    descricao: "",
    instancias: "",
    resposta_duplicada: "",
    resposta_encerrada: "",
    resposta_sucesso: "",
    resposta_iniciada: "",
    mensagem_ganhador: "",
    mensagem_grupo: "",
    vip_adicionado: 1,
    qtd_ganhadores: 1,
  });
  //const activeEnquete = (enqueteId: number) => {
  //  api.put(`/enquetes/ativar/${enqueteId}`).then((response) => {
  //    console.log(response);
  //    //updateIntanciesList();
  //    //closeModalInstancie();
  //  });
  //};

  //const desactiveEnquete = (enqueteId: number) => {
  //  api.put(`/enquetes/ativar/${enqueteId}`).then((response) => {
  //    console.log(response);
  //    //updateIntanciesList();
  //    //closeModalInstancie();
  //  });
  //};

  const fillFormDataWithItem = (item: ISorteioListData) => {
    setFormValues({
      ...item,
    });
  };

  const setFormData = (
    field: keyof ISorteioForm,
    value: string | number | null
  ) => {
    setFormValues((oldValue) => {
      set(oldValue, field, value);
      return oldValue;
    });
  };

  const createSorteio = (callback: () => void) => {
    api.post("/sorteios", formValues).then((response) => {
      callback();
      //updateIntanciesList();
      //closeModalInstancie();
    });
  };

  const getSorteio = (
    sorteioId: number,
    callback: (instancieData: ISorteioListData) => void
  ) => {
    api
      .get(`/sorteios/${sorteioId}`)
      .then(({ data }) => {
        callback(data);
      })
      .catch((error) => console.log("error", error));
  };

  const deleteSorteio = (sorteioId: number, callback: () => void) => {
    api.delete(`/sorteios/${sorteioId}`).then((response) => {
      callback();
      console.log("response", response);
    });
  };

  const updateSorteio = (
    enqueteId: number,
    callback: () => void,
    data?: ISorteioListData
  ) => {
    api
      .put(`/sorteios/${enqueteId}`, data ? data : formValues)
      .then((response) => {
        console.log(response);
        callback();
        //updateIntanciesList();
        //closeModalInstancie();
      });
  };
  const updateSorteiosList = async () => {
    setLoadingList(true);
    api
      .get<Array<ISorteioListData>>("/sorteios")
      .then(({ data }) => {
        setLoadingList(false);
        setSorteioList(data);
      })
      .catch((error) => console.log("error:", error));
  };
  const duplicateSorteio = (sorteioId: number, callback: () => void) => {
    api.post(`/sorteios/duplicar/${sorteioId}`).then((response) => {
      callback();
      //updateIntanciesList();
      //closeModalInstancie();
    });
  };
  return {
    sorteioList,
    createSorteio,
    getSorteio,
    deleteSorteio,
    setFormData,
    formValues,
    fillFormDataWithItem,
    updateSorteio,
    updateSorteiosList,
    duplicateSorteio,
    loadingList,
  };
};
