import React, { useState } from "react";
import { api } from "../Services/api";
import { IMessage } from "../types";
import { TFlags } from "../Components/MessageList/types";

interface IMessageForm {
  message: string;
}
export interface IModalMessage {
  setFormData: (field: keyof IMessageForm, value: string) => void;
  formValues: IMessageForm;
  flags?: Array<TFlags>;
}

export interface IUsedMessage {
  messageList: Array<IMessage>;
  updateMessageList: () => void;
  fillFormDataWithItem: (item: IMessage) => void;
  createMessage: (callback: () => void) => void;
  updateMessage: (id: number, callback: () => void) => void;
  deleteMessage: (id: number, callback: () => void) => void;
  setFormData: (field: keyof IMessageForm, value: string) => void;
  formValues: IMessageForm;
}
export const useMessage = (type: string): IUsedMessage => {
  const [messageList, setMessageList] = useState<Array<IMessage>>([]);

  const [formValues, setFormValues] = useState<IMessageForm>({
    message: "",
  });

  const fillFormDataWithItem = (item: IMessage) => {
    setFormValues({ message: item.message });
  };

  const setFormData = (field: keyof IMessageForm, value: string) => {
    setFormValues((oldValue) => {
      oldValue[field] = value;
      return oldValue;
    });
  };

  const updateMessageList = () => {
    api.get(`/message/${type}`).then(({ data }) => {
      setMessageList(data);
    });
  };

  const createMessage = (callback: () => void) => {
    api.post("/message", { ...formValues, area: type }).then(() => {
      callback();
    });
  };
  const updateMessage = (id: number, callback: () => void) => {
    api.put(`/message/${id}`, { ...formValues }).then(() => {
      callback();
    });
  };
  const deleteMessage = (id: number, callback: () => void) => {
    api.delete(`/message/${id}`).then(() => {
      callback();
    });
  };
  return {
    messageList,
    updateMessageList,
    formValues,
    fillFormDataWithItem,
    setFormData,
    createMessage,
    updateMessage,
    deleteMessage,
  };
};
