import { HeadersDefaults } from "axios";
import { set } from "lodash";
import { useState } from "react";
import { api } from "../../Services/api";
import { ICupomForm, ICupomListData } from "./types";

interface CommonHeaderProperties extends HeadersDefaults {
  Authorization: string;
}

export const useCupom = () => {
  const [cupomList, setCupomList] = useState<Array<ICupomListData>>([]);
  const [loadingList, setLoadingList] = useState(false);

  const [formValues, setFormValues] = useState<ICupomForm>({
    cupom: "",
    fixo: 0,
    percDesconto: 0,
    isActive: 1,
    cadastradoNatura: 0,
    qtdDisponivel: 20,
  });

  const fillFormDataWithItem = (item: ICupomListData) => {
    setFormValues({
      ...item,
    });
  };

  const setFormData = (
    field: keyof ICupomForm,
    value: string | number | null
  ) => {
    setFormValues((oldValue) => {
      set(oldValue, field, value);
      return oldValue;
    });
  };

  const createCupom = (callback: () => void) => {
    api.post("/cupons", formValues).then((response) => {
      callback();
    });
  };

  const getCupom = (
    cupomId: number,
    callback: (instancieData: ICupomListData) => void
  ) => {
    api
      .get(`/cupons/${cupomId}`)
      .then(({ data }) => {
        callback(data);
      })
      .catch((error) => console.log("error", error));
  };

  const deleteCupom = (cupomId: number, callback: () => void) => {
    api.delete(`/cupons/${cupomId}`).then((response) => {
      callback();
      console.log("response", response);
    });
  };

  const updateCupom = (
    cupomId: number,
    callback: () => void,
    data?: ICupomListData
  ) => {
    api.put(`/cupons/${cupomId}`, data ? data : formValues).then((response) => {
      console.log(response);
      callback();
    });
  };
  const updateCupomList = async () => {
    setLoadingList(true);
    api
      .get<Array<ICupomListData>>("/cupons")
      .then(({ data }) => {
        setLoadingList(false);
        setCupomList(data);
      })
      .catch((error) => console.log("error:", error));
  };

  return {
    cupomList,
    createCupom,
    getCupom,
    deleteCupom,
    setFormData,
    formValues,
    fillFormDataWithItem,
    updateCupom,
    updateCupomList,
    loadingList,
  };
};
