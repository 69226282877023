import React, { useEffect, useState } from "react";
import axios, { AxiosResponse, HeadersDefaults } from "axios";
import { api } from "../../Services/api";
import { IInstancieForm, IInstancieListData } from "./types";
import { CONFIG } from "../../../Config";
import { set } from "lodash";

interface CommonHeaderProperties extends HeadersDefaults {
  Authorization: string;
}

export const useInstancies = () => {
  const [instanciesList, setInstanciesList] = useState<
    Array<IInstancieListData>
  >([]);
  const [formValues, setFormValues] = useState<IInstancieForm>({
    name: "",
    port: "",
    robo: false,
    enquete: false,
    apenas_robo: true,
    sorteio: false,
  });
  const fillFormDataWithItem = (item: IInstancieListData) => {
    setFormValues({
      name: item.name,
      port: item.port.toString(),
      robo: item.robo,
      enquete: item.enquete,
      apenas_robo: item.apenas_robo,
      sorteio: item.sorteio,
    });
  };
  const setFormData = (field: keyof IInstancieForm, value: string | number) => {
    setFormValues((oldValue) => {
      set(oldValue, field, value);
      return oldValue;
    });
  };

  const getInstancieStarted = async (port: number) => {
    const apiInstancie = axios.create({
      baseURL: `${CONFIG.urlInstancies}:${port}`,
    });

    apiInstancie.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    apiInstancie.defaults.headers.get["Content-Type"] =
      "application/json;charset=utf-8";

    const initialized = await apiInstancie
      .get("/status")
      .then((response) => {
        return response.status === 200;
      })
      .catch(() => {
        return false;
      });
    return initialized;
  };

  const createInstancie = (callback: () => void) => {
    api.post("/instancies", formValues).then((response) => {
      callback();
      //updateIntanciesList();
      //closeModalInstancie();
    });
  };

  const getInstancie = (
    instancieId: number,
    callback: (instancieData: IInstancieListData) => void
  ) => {
    api
      .get(`/instancies/${instancieId}`)
      .then(({ data }) => {
        callback(data);
      })
      .catch((error) => console.log("error", error));
  };

  const deleteInstancie = (instancieId: number, callback: () => void) => {
    api.delete(`/instancies/${instancieId}`).then((response) => {
      callback();
      console.log("response", response);
      //updateIntanciesList();
      //closeDeleteConfirm();
    });
  };

  const updateInstancie = (instancieId: number, callback: () => void) => {
    api.put(`/instancies/${instancieId}`, formValues).then((response) => {
      callback();
      //updateIntanciesList();
      //closeModalInstancie();
    });
  };

  const updateIntanciesList = async () => {
    api
      .get<Array<IInstancieListData>>("/instancies")
      .then(async ({ data }) => {
        const listData = await Promise.all(
          data.map(async (instancieData) => {
            const started = await getInstancieStarted(instancieData.port);
            return { ...instancieData, started: started };
          })
        );
        setInstanciesList(listData);
      })
      .catch((error) => console.log("error:", error));
  };

  const initInstancie = (instancieId: number) => {
    api.get(`/instancies/start/${instancieId}`).then((response) => {
      console.log("response", response);
    });
  };
  return {
    instanciesList,
    updateIntanciesList,
    createInstancie,
    deleteInstancie,
    updateInstancie,
    setFormData,
    formValues,
    fillFormDataWithItem,
    getInstancie,
    initInstancie,
  };
};
