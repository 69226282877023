import React, { useEffect, useState } from "react";
import { useConfirm } from "../../Hooks/Confirm";
import { useModal } from "../../Hooks/Modal";
import { useEnquetes } from "./hook";
import {
  IEnqueteListData,
  IEnqueteOrRespostaData,
  IListItemProp,
  IListItemRespostaProps,
  IModalEnquete,
  IModalRespostaEnquete,
} from "./types";
import { MessageList } from "../../Components/MessageList";
import { useMessage } from "../../Hooks/Message";

export const Enquetes: React.FC = () => {
  const [enqueteRespostaData, setEnqueteRespostaData] =
    useState<IEnqueteOrRespostaData>({ id: 0, name: "" });

  const changeEnqueteRespostaData = (data: IEnqueteOrRespostaData) => {
    setEnqueteRespostaData(data);
  };

  const clearEnqueteRespostaData = () => {
    setEnqueteRespostaData({ id: 0, name: "" });
  };
  if (enqueteRespostaData?.id > 0) {
    return (
      <RespostasList
        enqueteRespostaData={enqueteRespostaData}
        clearEnqueteData={clearEnqueteRespostaData}
      />
    );
  }
  return <EnquetesList changeEnqueteResposta={changeEnqueteRespostaData} />;
};

interface IEnquetesListProps {
  changeEnqueteResposta: (data: IEnqueteOrRespostaData) => void;
}
const EnquetesList: React.FC<IEnquetesListProps> = ({
  changeEnqueteResposta,
}) => {
  const {
    createEnquete,
    setFormData,
    formValues,
    enqueteList,
    updateEnquetesList,
    loadingList,
  } = useEnquetes();

  const {
    Modal,
    show: openModalInstancie,
    hide: closeModalInstancie,
  } = useModal();

  useEffect(() => {
    updateEnquetesList();
  }, []);
  useEffect(() => {
    console.log("enquete alterada no component:", enqueteList);
  }, [enqueteList]);
  return (
    <>
      <Modal
        title="Criar Enquete"
        footer={
          <div className="d-flex gap-3">
            <button
              type="button"
              onClick={() => {
                createEnquete(() => {
                  updateEnquetesList();
                  closeModalInstancie();
                });
              }}
              className="btn btn-success"
            >
              Salvar
            </button>
            <button
              onClick={closeModalInstancie}
              type="button"
              className="btn btn-secondary"
            >
              Cancelar
            </button>
          </div>
        }
      >
        <ModalEnquete setFormData={setFormData} formValues={formValues} />
      </Modal>
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <h3>Enquetes</h3>
            <button
              type="button"
              onClick={openModalInstancie}
              className="btn btn-primary"
            >
              Nova enquete
            </button>
          </div>
        </div>
        <div className="card-body p-2">
          {!loadingList ? (
            enqueteList.map((enqueteData, key) => (
              <ListItem
                changeEnqueteResposta={changeEnqueteResposta}
                updateEnqueteList={updateEnquetesList}
                item={enqueteData}
                key={`ItemInstancieList_${key}`}
              />
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

interface IRespostasListProps {
  clearEnqueteData: () => void;
  enqueteRespostaData: IEnqueteOrRespostaData;
}
export const RespostasList: React.FC<IRespostasListProps> = ({
  clearEnqueteData,
  enqueteRespostaData,
}) => {
  const {
    createEnquete,
    createRespostaEnquete,
    setFormRespostaData,
    formRespostaValues,
    updateRespostasList,
    respostasList,
    enqueteList,
    updateEnquetesList,
  } = useEnquetes();

  const {
    Modal,
    show: openModalInstancie,
    hide: closeModalInstancie,
  } = useModal();

  const updateRespostasListData = () => {
    updateRespostasList(enqueteRespostaData.id);
  };
  useEffect(() => {
    updateRespostasList(enqueteRespostaData.id);
  }, []);

  return (
    <>
      <Modal
        zIndex={10}
        title="Criar resposta"
        footer={
          <div className="d-flex gap-3">
            <button
              type="button"
              onClick={() => {
                createRespostaEnquete(enqueteRespostaData.id, () => {
                  updateRespostasList(enqueteRespostaData.id);
                  closeModalInstancie();
                });
              }}
              className="btn btn-success"
            >
              Salvar
            </button>
            <button
              onClick={closeModalInstancie}
              type="button"
              className="btn btn-secondary"
            >
              Cancelar
            </button>
          </div>
        }
      >
        <ModalRespostaEnquete
          setFormData={setFormRespostaData}
          formValues={formRespostaValues}
        />
      </Modal>
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-3">
              <button
                type="button"
                className="btn btn-success btn-sm"
                onClick={clearEnqueteData}
              >
                Voltar
              </button>
              <div className="d-flex flex-column">
                <h3 className="p-0 m-0">Respostas:</h3>
                <small>{enqueteRespostaData.name}</small>
              </div>
            </div>
            <button
              type="button"
              onClick={openModalInstancie}
              className="btn btn-primary"
            >
              Nova resposta
            </button>
          </div>
        </div>
        <div className="card-body p-2">
          {respostasList.map((respostaList, key) => (
            <ListItemResposta
              updateRespostaList={updateRespostasListData}
              item={respostaList}
              key={`ItemResposeList_${respostaList.id}`}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const ListItem: React.FC<IListItemProp> = ({
  item,
  updateEnqueteList,
  changeEnqueteResposta,
}) => {
  console.log("lista recarregada");
  const {
    Confirm: ConfirmDelete,
    show: openDeleteConfirm,
    hide: closeDeleteConfirm,
  } = useConfirm();
  const [updating, setUpdating] = useState(false);
  const [itemList, setItemList] = useState<IEnqueteListData>(item);
  const {
    Modal,
    show: openModalInstancie,
    hide: closeModalInstancie,
  } = useModal();

  const {
    deleteEnquete,
    updateEnquete,
    setFormData,
    formValues,
    fillFormDataWithItem,
    duplicateEnquete,
  } = useEnquetes();

  useEffect(() => {
    fillFormDataWithItem(itemList);
  }, [itemList]);

  const switchEnquete = () => {
    if (!updating) {
      setUpdating(true);
      const item = { ...itemList, ativo: !itemList.ativo };
      setItemList(item);
      fillFormDataWithItem(item);
      updateEnquete(
        itemList.id,
        () => {
          setUpdating(false);
        },
        item
      );
    }
  };
  return (
    <>
      <ConfirmDelete
        btnLabel="Remover"
        btnColor="danger"
        callback={() => {
          deleteEnquete(itemList.id, () => {
            updateEnqueteList();
            closeDeleteConfirm();
          });
        }}
        title="Deseja realmente remover a enquete?"
      >
        Deseja realmente remover a enquete? A remoção é permanente.
      </ConfirmDelete>

      <Modal
        title="Editar Enquete"
        footer={
          <div className="d-flex gap-3">
            <button
              type="button"
              onClick={() => {
                updateEnquete(itemList.id, () => {
                  updateEnqueteList();
                  closeModalInstancie();
                });
              }}
              className="btn btn-success"
            >
              Salvar
            </button>
            <button
              onClick={closeModalInstancie}
              type="button"
              className="btn btn-secondary"
            >
              Cancelar
            </button>
          </div>
        }
      >
        <ModalEnquete
          id={itemList.id}
          setFormData={setFormData}
          formValues={formValues}
        />
      </Modal>

      <div
        className={`d-flex flex-column flex-md-row border-1 rounded bg-secondary bg-opacity-10 my-3 p-3 justify-content-between ${
          updating ? "opacity-25" : ""
        }`}
      >
        <div className="d-flex flex-column flex-md-row w-100 justify-content-between">
          <div className="d-flex flex-column mx-3">
            <b>Nome:</b>
            <span>{itemList.nome}</span>
          </div>

          <div className="d-flex flex-column mx-3">
            <b>Ativo:</b>
            <div className="form-check form-switch">
              <input
                className="form-check-input cursor-pointer"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                onClick={switchEnquete}
                checked={itemList.ativo}
              />
            </div>
            {/* <span>{item.ativo ? "Ativo" : "Inativo"}</span> */}
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex flex-row mx-3 gap-2">
            {/* {!item.started && (
              <button
                type="button"
                onClick={openStartInstancieModal}
                className="btn btn-primary"
              >
                Iniciar
              </button>
            )} */}

            <button
              onClick={() => {
                changeEnqueteResposta({ id: itemList.id, name: itemList.nome });
              }}
              type="button"
              className="btn btn-info"
            >
              Respostas
            </button>

            <button
              onClick={openModalInstancie}
              type="button"
              className="btn btn-warning"
            >
              Editar
            </button>
            <button
              onClick={openDeleteConfirm}
              type="button"
              className="btn btn-danger"
            >
              Remover
            </button>
            <button
              onClick={() => {
                window.open(
                  `https://www.yesfb.com/participantes/?id=${itemList.id}`
                );
              }}
              type="button"
              className="btn btn-success"
            >
              Participantes
            </button>
            <button
              onClick={() => {
                duplicateEnquete(itemList.id, updateEnqueteList);
              }}
              type="button"
              className="btn btn-dark"
            >
              Duplicar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const ListItemResposta: React.FC<IListItemRespostaProps> = ({
  item,
  updateRespostaList,
}) => {
  const {
    Confirm: ConfirmDelete,
    show: openDeleteConfirm,
    hide: closeDeleteConfirm,
  } = useConfirm();

  const {
    Modal,
    show: openModalInstancie,
    hide: closeModalInstancie,
  } = useModal();
  const {
    deleteEnqueteResposta,
    formRespostaValues,
    fillFormRespostaDataWithItem,
    setFormRespostaData,
    updateResposta,
  } = useEnquetes();

  useEffect(() => {
    fillFormRespostaDataWithItem(item);
  }, [item]);

  return (
    <>
      <ConfirmDelete
        btnLabel="Remover"
        btnColor="danger"
        callback={() => {
          deleteEnqueteResposta(item.id, () => {
            updateRespostaList();
            closeDeleteConfirm();
          });
        }}
        title="Deseja realmente remover a enquete?"
      >
        Deseja realmente remover a resposta da enquete? A remoção é permanente.
      </ConfirmDelete>

      <Modal
        title="Editar Resposta"
        footer={
          <div className="d-flex gap-3">
            <button
              type="button"
              onClick={() => {
                updateResposta(item.id, () => {
                  updateRespostaList();
                  closeModalInstancie();
                });
              }}
              className="btn btn-success"
            >
              Salvar
            </button>
            <button
              onClick={closeModalInstancie}
              type="button"
              className="btn btn-secondary"
            >
              Cancelar
            </button>
          </div>
        }
      >
        <ModalRespostaEnquete
          formValues={formRespostaValues}
          setFormData={setFormRespostaData}
        />
      </Modal>

      <div className="d-flex flex-column flex-md-row border-1 rounded bg-secondary bg-opacity-10 my-3 p-3 justify-content-between">
        <div className="d-flex flex-column flex-md-row">
          <div className="d-flex flex-column mx-3">
            <b>Resposta:</b>
            <span>{item.resposta}</span>
          </div>
          <div className="d-flex flex-column mx-3">
            <b>Descricao:</b>
            <span>{item.descricao}</span>
          </div>
          <div className="d-flex flex-column mx-3">
            <b>Total de votos:</b>
            <span>{item.total_votos}</span>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex flex-row mx-3 gap-2">
            <button
              onClick={openModalInstancie}
              type="button"
              className="btn btn-warning"
            >
              Editar
            </button>
            <button
              onClick={openDeleteConfirm}
              type="button"
              className="btn btn-danger"
            >
              Remover
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const ModalEnquete: React.FC<IModalEnquete> = ({
  setFormData,
  formValues,
  id = 0,
}) => {
  const usedMessage = useMessage(`enquete${id}`);
  useEffect(() => {
    if (id) {
      usedMessage.updateMessageList();
    }
  }, []);
  return (
    <div className="d-flex flex-column">
      <div className="mb-3">
        <label className="form-label">Nome:</label>
        <input
          type="text"
          className="form-control"
          defaultValue={formValues.nome}
          onChange={(event) => {
            setFormData("nome", event.target.value);
          }}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Ativo:</label>
        <input
          type="checkbox"
          className="form-check-input mx-2"
          defaultChecked={formValues.ativo}
          onChange={(event) => {
            setFormData("ativo", event.target.checked ? 1 : 0);
          }}
        />
      </div>
      {id ? (
        <div className="card">
          <div className="card-header">
            <h6>Resposta do voto:</h6>
          </div>
          <div className="card-body">
            <MessageList flags={["{{Contato}}"]} usedMessage={usedMessage} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const ModalRespostaEnquete: React.FC<IModalRespostaEnquete> = ({
  setFormData,
  formValues,
}) => {
  return (
    <div>
      <div className="mb-3">
        <label className="form-label">Descrição:</label>
        <input
          type="text"
          className="form-control"
          defaultValue={formValues.descricao}
          onChange={(event) => {
            setFormData("descricao", event.target.value);
          }}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Resposta:</label>
        <input
          type="text"
          className="form-control"
          defaultValue={formValues.resposta}
          onChange={(event) => {
            setFormData("resposta", event.target.value);
          }}
        />
      </div>
    </div>
  );
};
