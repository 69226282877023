import React, { useEffect, useState } from "react";
import { IUseConfirm, useConfirm } from "../../Hooks/Confirm";
import { useInstancieContext } from "../../Context/instancie";
import axios from "axios";
import { CONFIG } from "../../../Config";

interface IModalSender {
  useConfirm: IUseConfirm;
  routeToSend: "send-publicity" | "send-bag" | "send-birthday";
  test?: boolean;
  callbackAtSend?: () => void;
}
export const ModalSender: React.FC<IModalSender> = ({
  useConfirm,
  routeToSend,
  test,
  callbackAtSend,
}) => {
  const { Confirm, hide } = useConfirm;
  const { instanciesList } = useInstancieContext();
  const inicializedInstancie = instanciesList.filter(
    (instancie) => instancie.started
  );
  const [selectedInstancies, setSelectedInstancies] = useState<Array<number>>(
    []
  );
  const selectInstancie = (port: number, selected: boolean) => {
    setSelectedInstancies((oldValue) =>
      oldValue.filter((value) => value !== port)
    );

    if (selected) {
      setSelectedInstancies((oldValue) => {
        return [...oldValue, port];
      });
    }
  };
  const callback = () => {
    if (selectedInstancies.length) {
      if (callbackAtSend) {
        callbackAtSend();
      }
      selectedInstancies.map((instanciePort) => {
        axios.get(
          `${CONFIG.urlInstancies}:${instanciePort}/${routeToSend}${
            test ? "?test=true" : ""
          }`
        );
      });
      hide();
      return;
    }
    alert("Nenhuma instancia selecionada.");
  };
  return (
    <Confirm callback={callback} title="Confirmar envio das mensagens">
      <div className="d-flex flex-column">
        <p>Selecione as instancias para o envio das mensagens:</p>
        {inicializedInstancie.length > 0 ? (
          inicializedInstancie
            .filter((instancie) => !instancie.apenas_robo)
            .map((instancie) => {
              return (
                <div
                  className="d-flex gap-1"
                  key={`ModalSender_${instancie.id}`}
                >
                  <input
                    type="checkbox"
                    onChange={(event) => {
                      selectInstancie(
                        instancie.port,
                        event.currentTarget.checked
                      );
                    }}
                  />
                  {instancie.name}
                </div>
              );
            })
        ) : (
          <b>Nenhuma instancia iniciada.</b>
        )}
      </div>
    </Confirm>
  );
};
