import "moment/locale/pt-br";
import React, { useEffect, useState } from "react";
import "react-datetime/css/react-datetime.css";
import { useModal } from "../../Hooks/Modal";
import { useGrupos } from "./hook";

import { VirtualMessageList } from "../../Components/VirtualMessageList";
import { useVirtualMessage } from "../../Hooks/VirtualMessage";
import {
  IGruposForm,
  IGruposListData,
  IListItemProp,
  IModalGrupo,
} from "./types";

export const Grupos: React.FC = () => {
  return <EnquetesList />;
};

const EnquetesList: React.FC = () => {
  const usedGrupos = useGrupos();
  const { setFormData, formValues, gruposList, updateGrupoList } = usedGrupos;

  useEffect(() => {
    updateGrupoList();
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <h3>Grupos</h3>
          </div>
        </div>
        <div className="card-body p-2">
          {gruposList.map((grupoData, key) => (
            <ListItem
              usedGrupos={usedGrupos}
              item={grupoData}
              key={`ItemInstancieList_${key}`}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const ListItem: React.FC<IListItemProp> = ({ item, usedGrupos }) => {
  const [updating, setUpdating] = useState(false);
  const [itemList, setItemList] = useState<IGruposListData>(item);
  const {
    Modal,
    show: openModalInstancie,
    hide: closeModalInstancie,
  } = useModal();

  const { updateGrupoList } = usedGrupos;
  const newUsedGrupos = useGrupos();
  const { updateGrupo, fillFormDataWithItem } = newUsedGrupos;
  useEffect(() => {
    fillFormDataWithItem(itemList);
  }, [itemList]);

  return (
    <>
      <Modal
        title="Editar Grupo"
        footer={
          <div className="d-flex gap-3">
            <button
              type="button"
              onClick={() => {
                updateGrupo(itemList.id, () => {
                  updateGrupoList();
                  closeModalInstancie();
                });
              }}
              className="btn btn-success"
            >
              Salvar
            </button>
            <button
              onClick={closeModalInstancie}
              type="button"
              className="btn btn-secondary"
            >
              Cancelar
            </button>
          </div>
        }
      >
        <ModalGrupo id={itemList.id} usedGrupos={newUsedGrupos} />
      </Modal>

      <div
        className={`d-flex flex-column flex-md-row border-1 rounded bg-secondary bg-opacity-10 my-3 p-3 justify-content-between ${
          updating ? "opacity-25" : ""
        }`}
      >
        <div className="d-flex flex-column flex-md-row w-100 justify-content-between">
          <div className="d-flex flex-column mx-3">
            <b>Nome:</b>
            <span>{itemList.nome}</span>
          </div>
          <div className="d-flex flex-column mx-3">
            <b>Total Participantes:</b>
            <span>{itemList.total_contatos}</span>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex flex-row mx-3 gap-2">
            <button
              onClick={openModalInstancie}
              type="button"
              className="btn btn-warning"
            >
              Editar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const ModalGrupo: React.FC<IModalGrupo> = ({ usedGrupos, id = 0 }) => {
  const { formValues, setFormData } = usedGrupos;
  const usedVirtualMessageWelcome = useVirtualMessage<IGruposForm>({
    keyFormValue: "msg_boas_vindas",
    setFormOutedData: setFormData,
  });
  const [replicarMsg, setReplicarMsg] = useState(1);

  const changeReplicarMsg = () => {
    setReplicarMsg((oldValue) => {
      const newValue = oldValue === 1 ? 0 : 1;
      setFormData("replicar_mensagem", newValue);
      return newValue;
    });
  };

  useEffect(() => {
    usedVirtualMessageWelcome.setMessageList(
      formValues.msg_boas_vindas ? JSON.parse(formValues.msg_boas_vindas) : []
    );
  }, []);

  return (
    <div className="d-flex flex-column">
      <div className=" border-1 p-2">
        <div id="config" className={`flex-column`}>
          <div className="d-flex flex-row align-items-end">
            <div className="mb-3 col-10">
              <span>Nome do grupo: </span>
              <b>{formValues.nome}</b>
              {/* <input
                type="text"
                className="form-control"
                defaultValue={formValues.nome}
                onChange={(event) => {
                  setFormData("nome", event.target.value);
                }}
              /> */}
            </div>

            {/*  <div className="mb-3 col-2 ps-3">
          <label className="form-label">Ativo:</label>
          <input
            type="checkbox"
            className="form-check-input mx-2"
            defaultChecked={formValues.ativo ? true : false}
            onChange={(event) => {
              setFormData("ativo", event.target.checked ? 1 : 0);
            }}
          />
        </div> */}
          </div>

          <div className="d-flex flex-column">
            <div className="card">
              <div className="card-header">
                <div className="d-flex flex-row justify-content-between">
                  <h6>Mensagem de boas vindas:</h6>
                  <div className="d-flex gap-2">
                    <label>Replicar mensagem para outros grupos:</label>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input cursor-pointer"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        onClick={changeReplicarMsg}
                        checked={!!replicarMsg ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <VirtualMessageList
                  flags={["{{Contato}}"]}
                  usedVirtualMessage={usedVirtualMessageWelcome}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
