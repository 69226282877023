import React, { useEffect, useState } from "react";
import { useModal } from "./Modal";

interface useConfirmProps {
  callback: () => void;
}

interface ConfirmProps {
  callback: () => void;
  title: string;
  children: React.ReactNode;
  btnLabel?: string;
  btnColor?: "primary" | "warning" | "danger" | "success" | "secondary";
  opened?: boolean;
}

export interface IUseConfirm {
  Confirm: React.FC<ConfirmProps>;
  show: () => void;
  hide: () => void;
  visible: boolean;
}
export const useConfirm = (): IUseConfirm => {
  const { Modal, show, hide, visible } = useModal();
  const Confirm: React.FC<ConfirmProps> = ({
    children,
    title,
    callback,
    btnLabel = "Confirmar",
    btnColor = "success",
    opened = false,
  }) => {
    useEffect(() => {
      opened && show();
    }, [opened]);
    return (
      <Modal
        title={title}
        footer={
          <div className="d-flex gap-3">
            <button onClick={callback} className={`btn btn-${btnColor}`}>
              {btnLabel}
            </button>
            <button onClick={hide} className={`btn btn-secondary`}>
              Cancelar
            </button>
          </div>
        }
      >
        {children}
      </Modal>
    );
  };
  return { Confirm, show, hide, visible };
};
